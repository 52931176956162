import React, { ReactElement } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import PageLayout from '../PageLayout'
import Dashboard from 'pages/Dashboard'
import Buttons from 'pages/Buttons'
import DeviceTable from 'pages/DeviceTable'
import ItemTable from 'pages/ItemTable'
import DiskTable from 'pages/DiskTable'
import MemoryTable from 'pages/MemoryTable'
import SpecialItemTable from 'pages/SpecialItemTable'

import ThemeMapping from 'pages/ThemeMapping'
import Typography from 'pages/Typography'
import Typescript from 'pages/Typescript'
import { Cards } from 'pages/Cards/Cards'
import { Colors } from 'pages/Colors/Colors'
import Header from 'components/Header'
import MainNav from 'components/MainNav'
import { Forms } from 'pages/Forms/Forms'
import { MainForm } from 'pages/Chat/MainForm'
import { MainImageForm } from 'pages/ChatImage/MainImageForm'
import { MainModelsForm } from 'pages/ChatModels/MainModelsForm'
import { MainCodeForm } from 'pages/ChatCode/MainCodeForm'
import { Navigation } from 'pages/Navigation/Navigation'
import { Grids } from 'pages/Grid/Grids'
import Todos from 'pages/TodosList'
import { DeviceDetails } from 'pages/Dashboard/DeviceDetails'
import { Faq } from 'pages/Faq/Faq'
import { Contact } from 'pages/Contact/Contact'
import { Privacy } from 'pages/Privacy/Privacy'

/*
 * Render the route page next to the MainNav, which is a MUI Drawer component
 * that is permanently affixed to the left side of the page.
 * Also need to adjust the <div> styles to accommodate the route page next to the Navigation Drawer.
 * This can also be encapsulated in the PageLayout component if we pass the route component
 * as a child and render it there instead of here.
 */
export default function Routes(): ReactElement {
  return (
    <>
      <div style={{ display: 'flex' }}>
        {/*<Header /> */}
        <MainNav />
        <main style={{ flexGrow: 1, padding: '4rem' }}>
          {/* theme.mixins.toolbar adds a minimum height to an element. 
                It's useful when you use the AppBar with a content section below, 
                and you want to add a spacer at the top of your content so it doesn't 
                disappear under the AppBar, for example.
            */}
          <div style={{ /*theme.mixins.toolbar*/ minHeight: '4px' }} />
          <Switch>
            <Route path={'/todos'} component={Todos} />
            <Route path={'/deviceprices'} component={DeviceTable} />
            {/*<Route path={'/itemprices'} component={ItemTable} />*/}
            <Route path={'/specialprices'} component={SpecialItemTable} />
            <Route path={'/buttons'} component={Buttons} />
            <Route path={'/cards'} component={Cards} />
            <Route path={'/colors'} component={Colors} />
            <Route path={'/faq'} component={Faq} />
            <Route path={'/contact'} component={Contact} />
            <Route path={'/privacy'} component={Privacy} />
            <Route path={'/forms'} component={Forms} />
            <Route path={'/chat'} component={MainForm} />
            <Route path={'/chatimage'} component={MainImageForm} />
            <Route path={'/chatmodels'} component={MainModelsForm} />
            <Route path={'/chatcode'} component={MainCodeForm} />
            <Route path={'/grids'} component={Grids} />
            <Route path={'/navigation'} component={Navigation} />
            <Route path={'/typography'} component={Typography} />
            <Route path={'/typescript'} component={Typescript} />
            <Route path={'/thememapping'} component={ThemeMapping} />
            <Route path={'/drives'} component={DiskTable} />
            <Route path={'/memory'} component={MemoryTable} />
            {/*
            <Route path={'/devices/:id'}>
              <PageLayout>
                <DeviceDetails />
              </PageLayout>
            </Route>
          */}
            <Route exact path={'/'} component={ItemTable} />
            <Route path="*">
              <Redirect to={'/'} />
            </Route>
          </Switch>
        </main>
      </div>
    </>
  )
}
