import { StringCellParams, Item, ItemColDefs, ToolTipGetterParams } from 'types'

import { LinkCellProps } from 'components/Shared/Table/CellRenderers/LinkCell'
import { ValueFormatterParams } from 'ag-grid-community'

const numberSort = (num1: number, num2: number) => {
  return num1 - num2
}

export const colDefs: ItemColDefs[] = [
  {
    headerName: 'Brand',
    field: 'title',
    cellRenderer: 'linkCell',
    width: 400,
    cellRendererParams: (rowData: StringCellParams<Item>): Partial<LinkCellProps> => {
      const { data } = rowData
      const displayValue = data.title
      const href = `${data.detailPageURL}`
      return { label: displayValue, href }
    },
  },
  {
    headerName: 'Price',
    field: 'price',
    sort: 'asc',
    comparator: numberSort,
    valueFormatter: (rowData: ValueFormatterParams): string => {
      const { data } = rowData
      return '$' + data.price || '---'
    },
    tooltipValueGetter: (): string => {
      return 'Best price found'
    },
  },
  {
    headerName: 'OS',
    field: 'type',
  },
  {
    headerName: 'CPU',
    field: 'cpu',
    valueFormatter: (rowData: ValueFormatterParams): string => {
      const { data } = rowData
      return data.cpu
    },
  },
  {
    headerName: 'Mem',
    field: 'memory',
    comparator: numberSort,
    valueFormatter: (rowData: ValueFormatterParams): string => {
      const { data } = rowData
      return data.memory ? data.memory + ' GB' : ''
    },
  },
  {
    headerName: 'Storage',
    field: 'storage',
    comparator: numberSort,
    valueFormatter: (rowData: ValueFormatterParams): string => {
      const { data } = rowData
      if (data.storage) {
        return Number(data.storage) != 1 ? data.storage + ' GB' : data.storage + ' TB'
      }
      return ''
    },
  },
  {
    headerName: 'Display',
    field: 'display',
  },
  {
    headerName: 'Condition',
    field: 'condition',
    valueFormatter: (rowData: ValueFormatterParams): string => {
      const { data } = rowData
      return data.condition || '---'
    },
    tooltipValueGetter: (): string => {
      return 'New, used, refurbished, etc'
    },
  },
]
