import React, { FC } from 'react'
import { Text } from 'components/Shared'
import { constants } from 'styles/constants'

export const Colors: FC = () => {
  return (
    <>
      <Text variant="h1" style={{ padding: '0 0 4rem 0' }}>
        Colors
      </Text>

      <Text variant="h5" color={constants.colors.primaryMain} gutterBottom>
        Primary Main
      </Text>
      <Text variant="h5" color={constants.colors.primaryLight} gutterBottom>
        Primary Light
      </Text>
      <Text variant="h5" color={constants.colors.primaryDark} gutterBottom>
        Primary Dark
      </Text>
      <Text variant="h5" color={constants.colors.secondaryMain} gutterBottom>
        Secondary Main
      </Text>
      <Text variant="h5" color={constants.colors.secondaryLight} gutterBottom>
        Secondary Light
      </Text>
      <Text variant="h5" color={constants.colors.secondaryDark} gutterBottom>
        Secondary Dark
      </Text>
      <Text variant="h5" color={constants.colors.error} gutterBottom>
        Error
      </Text>
      <Text variant="h5" color={constants.colors.errorLight} gutterBottom>
        Error Light
      </Text>
      <Text variant="h5" color={constants.colors.success} gutterBottom>
        Success
      </Text>
      <Text variant="h5" color={constants.colors.successLight} gutterBottom>
        Success Light
      </Text>
      <Text variant="h5" color={constants.colors.warn} gutterBottom>
        Warn
      </Text>
      <Text variant="h5" color={constants.colors.warnLight} gutterBottom>
        Warn Light
      </Text>
      <Text variant="h5" color={constants.colors.neutral} gutterBottom>
        Neutral
      </Text>
      <Text variant="h5" color={constants.colors.neutralLight} gutterBottom>
        Neutral Light
      </Text>
      <Text variant="h5" color={constants.colors.neutralDark} gutterBottom>
        Neutral Dark
      </Text>
      <Text variant="h5" color={constants.colors.grey} gutterBottom>
        Grey
      </Text>
      <Text variant="h5" color={constants.colors.black} gutterBottom>
        Black
      </Text>
    </>
  )
}
