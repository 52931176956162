import React from 'react'
import { CSSProperties } from '@mui/styles'
import { PillCellProps, IconCellProps } from 'components/Shared/Table/CellRenderers'
import { PillColorOptions } from 'components/Shared/Pill/Pill'
import {
  AssignmentStatus,
  PowerStatus,
  DeviceFlag,
  DeviceColDefs,
  AssignmentStatusCellParams,
  PowerStatusCellParams,
  StringCellParams,
  Device,
} from 'types'
import { constants } from 'styles'
import { FlagIcon } from 'assets/icons'
import { DeviceFlagsCellParams } from 'types/GridParams'
import { LinkCellProps } from 'components/Shared/Table/CellRenderers/LinkCell'
import { EditableCallbackParams } from 'ag-grid-community'
import { history } from 'utils/history'

export const colDefs: DeviceColDefs[] = [
  {
    headerName: 'Device ID',
    field: 'id',
    cellRenderer: 'linkCell',
    cellRendererParams: (rowData: StringCellParams<Device>): Partial<LinkCellProps> => {
      const { data } = rowData
      //const href = `/devices/${data.id}`
      const href = `https://www.amazon.com/dp/B0199980K4?tag=xyz-20&linkCode=ogi&language=en_US&th=1&psc=1`
      return { href }
    },
  },
  {
    headerName: 'Status',
    field: 'assignmentStatus',
    cellStyle: (rowData: AssignmentStatusCellParams): CSSProperties => {
      let style: CSSProperties
      switch (rowData.value) {
        case AssignmentStatus.PendingReturn:
          style = {
            color: constants.colors.error,
          }
          break
        default:
          style = {}
          break
      }
      return style
    },
  },
  {
    headerName: 'Assignee',
    field: 'assignee',
  },
  {
    headerName: 'Device Model',
    field: 'model',
  },
  {
    headerName: 'Power Status',
    field: 'powerStatus',
    cellRenderer: 'pillCell',
    cellRendererParams: (rowData: PowerStatusCellParams): Partial<PillCellProps> => {
      let palette: PillColorOptions | undefined
      switch (rowData.value) {
        case PowerStatus.On:
          palette = 'success'
          break
        case PowerStatus.Off:
          palette = 'error'
          break
        case PowerStatus.NotActivated:
          palette = 'secondary'
          break
        case PowerStatus.Unknown:
          palette = 'warning'
          break
        default:
          palette = undefined
          break
      }
      return {
        palette,
      }
    },
  },
  {
    headerName: 'Flags',
    field: 'flags',
    cellRenderer: 'iconCell',
    cellRendererParams: (rowData: DeviceFlagsCellParams): Partial<IconCellProps> => {
      let iconCell: IconCellProps
      switch (rowData.value) {
        case DeviceFlag.Broken:
          iconCell = {
            value: DeviceFlag.Broken,
            StartIcon: <FlagIcon color="error" fontSize="small" />,
          }
          break
        default:
          iconCell = {
            value: '',
          }
          break
      }
      return iconCell
    },
  },
]
