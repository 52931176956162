import React, { useEffect, useState } from 'react'
import useAllItems from 'hooks/specialitems/useAllSpecialItems'
import { Pill, Table, Text } from 'components/Shared'
import { colDefs } from 'constants/data/specialItemGridColumnDefs'
import { Item } from 'types'
import Footer from 'components/Footer'

const labelNode = (label: string, value: number) => (
  <>
    {label}
    <Pill label={value} size="small" />
  </>
)

export const SpecialItemTable = (): JSX.Element => {
  //export default function Items(): ReactElement {
  const [Items, setItems] = useState<Item[]>([])
  const [filteredItems, setFilteredItems] = useState<Item[]>([])
  const [loading, setLoading] = useState(false)
  const [tabValue, setTabValue] = useState(0)

  const itemsResponse = useAllItems()

  useEffect(() => {
    const { result, loading } = itemsResponse
    setItems(result || [])
    setFilteredItems(result || [])
    setLoading(loading)
  }, [itemsResponse.result])

  return (
    <div>
      <Text variant="body1" style={{ padding: '0 0 2rem 0' }}>
        These are specialty items used for testing or other purposes and have limited availability.
      </Text>
      <Table columnDefs={colDefs} rowData={filteredItems} paginated={true} />
      <Footer />
    </div>
  )
}
