import { MemoryDto } from 'types/Memory'

export const mock_memory: MemoryDto[] = [
  {
    asin: 'B08C4Z69LN',
    detailPageURL: 'https://amzn.to/3TmSPCU',
    itemInfo: {
      features: {
        displayValues: ['Crucial RAM 8GB DDR4 3200MHz CL22 (or 2933MHz or 2666MHz)', '8GB'],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Crucial RAM 8GB DDR4 3200MHz CL22',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '19.99',
            currency: 'USD',
            displayAmount: '$19.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B08C511GQH',
    detailPageURL: 'https://amzn.to/3IOLIOo',
    itemInfo: {
      features: {
        displayValues: ['Crucial RAM 16GB DDR4 3200MHz CL22 (or 2933MHz or 2666MHz)', '16GB'],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Crucial RAM 16GB DDR4 3200MHz CL22',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '39.86',
            currency: 'USD',
            displayAmount: '$39.86',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B08GSTF5NJ',
    detailPageURL: 'https://amzn.to/494jB8T',
    itemInfo: {
      features: {
        displayValues: [
          'Corsair Vengeance Performance SODIMM Memory 32GB (2x16GB) DDR4 3200MHz CL22 ',
          '32BG',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Corsair Vengeance Performance SODIMM Memory 32GB',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '74.99',
            currency: 'USD',
            displayAmount: '$74.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B094WBGZLP',
    detailPageURL: 'https://amzn.to/494jTwv',
    itemInfo: {
      features: {
        displayValues: ['PNY XLR8 Gaming 32GB (2x16GB) DDR4 DRAM 3200MHz (PC4-25600) CL20', '32GB'],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'PNY XLR8 Gaming 32GB (2x16GB) DDR4',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '67.99',
            currency: 'USD',
            displayAmount: '$67.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0868BP6TP',
    detailPageURL: 'https://amzn.to/3THbzhz',
    itemInfo: {
      features: {
        displayValues: [
          'Patriot Signature Line Series DDR4 32GB (1 x 3G2B) 2666MHz SODIMM Single',
          '32GB',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Patriot Signature Line Series DDR4 32GB (1 x 3G2B)',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '63.99',
            currency: 'USD',
            displayAmount: '$63.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B07WJY3PX7',
    detailPageURL: 'https://amzn.to/3Vp7TSP',
    itemInfo: {
      features: {
        displayValues: [
          'A-Tech 64GB (2x32GB) DDR4 2666MHz PC4-21300 (PC4-2666V) CL19 SODIMM 2Rx8',
          '64GB',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'A-Tech 64GB (2x32GB) DDR4 2666MHz PC4-21300',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '159.40',
            currency: 'USD',
            displayAmount: '$159.40',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B07WGRBGNF',
    detailPageURL: 'https://amzn.to/3VnEMPS',
    itemInfo: {
      features: {
        displayValues: ['A-Tech 32GB DDR4 2666MHz PC4-21300 (PC4-2666V) CL19 SODIMM 2Rx8 ', '32GB'],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'A-Tech 32GB DDR4 2666MHz PC4-21300',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '79.41',
            currency: 'USD',
            displayAmount: '$79.41',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B07YXH7JNY',
    detailPageURL: 'https://amzn.to/3wUIKoY',
    itemInfo: {
      features: {
        displayValues: [
          'Timetec 32GB DDR4 2666MHz PC4-21300 Non-ECC Unbuffered 1.2V CL19 2Rx8',
          '32GB',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Timetec 32GB DDR4 2666MHz PC4-21300',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '59.99',
            currency: 'USD',
            displayAmount: '$59.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B08KGL4Y9G',
    detailPageURL: 'https://amzn.to/3wTpPeb',
    itemInfo: {
      features: {
        displayValues: ['Gigastone Laptop RAM 16GB DDR4 16GB DDR4-2666MHz PC4-21300 CL19', '16GB'],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Gigastone Laptop RAM 16GB DDR4 16GB',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '39.99',
            currency: 'USD',
            displayAmount: '$39.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B092XXR43F',
    detailPageURL: 'https://amzn.to/48WKOu9',
    itemInfo: {
      features: {
        displayValues: ['Acer SD100 16GB Single RAM 2666 MHz DDR4 CL19', '16GB'],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Acer SD100 16GB Single RAM',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '34.99',
            currency: 'USD',
            displayAmount: '$34.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
]
