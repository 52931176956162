import React, { ElementType, ReactElement } from 'react'
import { Typography, TypographyProps } from '@mui/material'
import { Color, FontStyleProperty, FontWeightProperty } from 'csstype'
import useTextStyles from './styles'

export interface TextProps extends TypographyProps {
  fontWeight?: FontWeightProperty
  fontStyle?: FontStyleProperty
  component?: ElementType
  colorOverride?: Color
}

export default function Text({
  children,
  fontStyle,
  fontWeight,
  variant = 'body1',
  component,
  variantMapping = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    subtitle1: 'h6',
    subtitle2: 'h6',
    body1: 'p',
    body2: 'p',
  },
  colorOverride,
  ...props
}: TextProps): ReactElement {
  const classes = // only create classes if an override was passed
    (fontWeight || fontStyle || colorOverride) &&
    useTextStyles({ fontStyle, fontWeight, colorOverride })
  const componentOverride: ElementType = // component || variant mapping || 'span'
    component || (variant !== 'inherit' && (variantMapping[variant] as ElementType)) || 'span'
  const classesList = // cannot override `inherit` variant
    variant !== 'inherit' && classes
      ? {
          [variant]: classes.fontOverride,
        }
      : {}
  return (
    <Typography
      {...props}
      variant={variant}
      component={componentOverride} /*classes={classesList}*/
    >
      {children}
    </Typography>
  )
}
