import { useEffect, useState } from 'react'
import { chatService } from 'services/ChatService'
import { AsyncHookResponse, Model } from 'types/'

const useChatModels = (value: string): AsyncHookResponse<Model[]> => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [result, setResult] = useState<Model[]>()

  const fetchAIResponse = (value: string) => {
    setLoading(true)
    setError(false)

    chatService
      .getModelsResponse(value)
      .then((promptResponse) => {
        setResult(promptResponse)
        setLoading(false)
      })
      .catch((e) => {
        console.error('Error:', e)
        setError(true)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchAIResponse(value)
  }, [])

  return { result, loading, error }
}

export default useChatModels
