import roboto from './Roboto-Regular.ttf'
import robotoLight from './Roboto-Light.ttf'
import robotoMedium from './Roboto-Medium.ttf'
import robotoBold from './Roboto-Bold.ttf'
import robotoBlack from './Roboto-Black.ttf'
import robotoItalic from './Roboto-Italic.ttf'
import robotoLightItalic from './Roboto-LightItalic.ttf'
import robotoMediumItalic from './Roboto-MediumItalic.ttf'
import robotoBoldItalic from './Roboto-BoldItalic.ttf'
import robotoBlackItalic from './Roboto-BlackItalic.ttf'

const fonts = [
  // Normal
  roboto,
  robotoLight,
  robotoMedium,
  robotoBold,
  robotoBlack,
  // Italic
  robotoItalic,
  robotoLightItalic,
  robotoMediumItalic,
  robotoBoldItalic,
  robotoBlackItalic,
]

export default fonts

