import { useEffect, useState } from 'react'
import { deviceService } from 'services/DeviceService'
import { AsyncHookResponse, Device } from 'types'
import { useCancelToken } from 'hooks/util/useCancelToken'
import { CancelToken } from 'axios'

const useAllDevices = (): AsyncHookResponse<Device[]> => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [result, setResult] = useState<Device[]>([])
  const { newCancelToken, isCancel, currentToken } = useCancelToken()

  const fetchDevices = (cancelToken: CancelToken) => {
    setLoading(true)
    setError(false)

    deviceService
      .getAllDevices(cancelToken)
      .then((devices) => {
        setResult(devices)
        setLoading(false)
      })
      .catch((e) => {
        console.error('Error:', e)
        setError(true)
        setLoading(false)
      })
  }

  useEffect(() => {
    //TODO for client id
    /*
    if (id) {
      currentToken?.cancel()
      fetchDevice(id, newCancelToken())
    } else {
      setLoading(true)
      setError(false)
    }
    */
    currentToken?.cancel()
    fetchDevices(newCancelToken())
    return () => {
      currentToken?.cancel()
    }
  }, [])

  return { result, loading, error }
}

export default useAllDevices
