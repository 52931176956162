import React, { ReactElement } from 'react'
import { Text } from 'components/Shared'

export default function Typography(): ReactElement {
  return (
    <>
      <Text variant="h1" style={{ padding: '0 0 4rem 0' }}>
        Typography
      </Text>
      <Text variant="h1" color="textPrimary">
        h1: brix.typography.variants.headline,
      </Text>
      <Text variant="h2" color="textPrimary">
        h2: brix.typography.variants.pageTitle,
      </Text>
      <Text variant="h3" color="textPrimary">
        h3: brix.typography.variants.title,
      </Text>
      <Text variant="h4" color="textPrimary">
        h4: brix.typography.variants.heroTitle,
      </Text>
      <Text variant="h5" color="textPrimary">
        h5: brix.typography.variants.sectionTitle,
      </Text>
      <Text variant="h6" color="textPrimary">
        h6: brix.typography.variants.largeBody,
      </Text>
      <Text variant="body1" color="textPrimary">
        body1: brix.typography.variants.body,
      </Text>
      <Text variant="body2" color="textPrimary">
        body2: brix.typography.variants.smallBody,
      </Text>{' '}
      <Text variant="subtitle1" color="textPrimary">
        subtitle1: brix.typography.variants.disclaimer,
      </Text>
      <Text variant="subtitle2" color="textPrimary">
        subtitle2: brix.typography.variants.navigation,
      </Text>
      <Text variant="subtitle2" color="textPrimary">
        subtitle2: brix.typography.variants.navigation,
      </Text>
    </>
  )
}
