import { ItemDto, Item, Features } from 'types'

type Specs = 'cpu' | 'memory' | 'storage' | 'display' | 'type'

const specsMap: Record<Specs, string> = {
  cpu: '',
  memory: '',
  storage: '',
  display: '',
  type: '',
}

const parseFeatures = (features: Features): Record<Specs, string> => {
  if (!features) {
    return specsMap
  }

  const { displayValues } = features

  const mainTitle = displayValues[0]
  if (mainTitle.includes('Apple')) {
    specsMap['type'] = 'Mac OS'
  } else {
    specsMap['type'] = 'Windows'
  }

  //CPU
  const cpuVal = displayValues
    .filter(
      (value) =>
        value.includes('M1') ||
        value.includes('M2') ||
        value.includes('M3') ||
        value.includes('CPU') ||
        value.includes('Core') ||
        value.includes('Ryzen') ||
        value.includes('Processor') ||
        value.includes('Intel')
    )
    .toString()
  specsMap['cpu'] = cpuVal

  //RAM - remove SSD specs first
  const vals1 = displayValues.filter((value) => !value.includes('SSD'))
  let ramVal = vals1.find((value) => value.includes('RAM') || value.includes('Memory'))
  ramVal = ramVal?.includes('GB') ? ramVal?.split('GB')[0] : 'None'
  const ramStr = ramVal?.replace(/\D/g, '')
  specsMap['memory'] = ramStr ? ramStr : ''

  //Storage - remove RAM specs first
  const vals2 = displayValues.filter((value) => !value.includes('RAM'))
  let storageVal = vals2.find(
    (value) => value.includes('SSD') || value.includes('Storage') || value.includes('eMMC')
  )
  storageVal = storageVal?.includes('GB') ? storageVal?.split('GB')[0] : storageVal?.split('TB')[0]
  const storageStr = storageVal?.replace(/\D/g, '')
  specsMap['storage'] = storageStr ? storageStr : ''

  //Display
  specsMap['display'] = displayValues
    .filter(
      (value) =>
        value.includes('Inch') ||
        value.includes('inch') ||
        value.includes('Screen') ||
        value.includes('Display') ||
        value.includes('FHD') ||
        value.includes('UHD') ||
        value.includes('"') ||
        value.includes('”')
    )
    .toString()

  return specsMap
}

export const normalizeItem = (rawItem: ItemDto): Item => {
  const { asin, detailPageURL, itemInfo, offers } = rawItem
  const features = parseFeatures(itemInfo.features)

  return {
    asin,
    title: itemInfo.title.displayValue,
    price: offers.summaries[0].highestPrice.amount,
    type: features['type'],
    cpu: features['cpu'],
    memory: features['memory'],
    storage: features['storage'],
    display: features['display'],
    condition: offers.summaries[0].condition.value,
    detailPageURL,
  }
}
