import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Text } from 'components/Shared'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

export const Grids: React.FC = () => {
  const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }))

  const ItemCenter = styled(Paper)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }))
  const ItemStart = styled(Paper)(({ theme }) => ({
    textAlign: 'start',
    color: theme.palette.text.secondary,
    paddingLeft: '62px',
  }))
  const ItemEnd = styled(Paper)(({ theme }) => ({
    textAlign: 'end',
    color: theme.palette.text.secondary,
  }))

  const FlexItemType = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '22',
    border: '1px solid',
  }))

  const FlexItemValue = styled('div')(() => ({
    alignItems: 'end',
    fontWeight: 'bold',
    fontSize: '14px',
    paddingLeft: '0',
    border: '1px solid',
  }))

  const FlexDivRow = styled('div')(() => ({
    display: 'flex',
    padding: '10px 0px',
    alignItems: 'center',
    justifyContent: 'space-around',
    border: '1px solid',
  }))

  return (
    <Grid container direction="row" spacing={8}>
      <Grid item xs={12}>
        <Box>
          <Text variant="h1">FlexDiv 1</Text>

          <FlexDivRow>
            <FlexItemType>FlexItemType</FlexItemType>
            <FlexItemType>FlexItemType</FlexItemType>
            <FlexItemValue>FlexItemValue</FlexItemValue>
            <FlexItemValue>FlexItemValue</FlexItemValue>
          </FlexDivRow>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box>
          <Text variant="h1">Custom Grid Items</Text>

          <Grid container spacing={1}>
            <Grid item xs={3}>
              <ItemStart>
                <div>Item</div>
              </ItemStart>
            </Grid>
            <Grid item xs={3}>
              <ItemCenter>Item</ItemCenter>
            </Grid>
            <Grid item xs={3}>
              <ItemCenter>Item</ItemCenter>
            </Grid>
            <Grid item xs={3}>
              <ItemEnd>Type</ItemEnd>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box>
          <Text variant="h1">MUI 5 Grid Items AND Containers</Text>
          <Grid container spacing={1}>
            <Grid item xs={3} textAlign="start" style={{ height: 70, border: '1px solid black' }}>
              <div style={{ background: 'white' }}>Start</div>
            </Grid>
            <Grid
              item
              container
              xs={3}
              justifyContent="center"
              style={{ height: 80, border: '1px solid black' }}
            >
              <div style={{ background: 'white' }}>Center</div>
            </Grid>
            <Grid
              item
              container
              xs={3}
              justifyContent="center"
              alignItems="center"
              style={{ height: 90, border: '1px solid black' }}
            >
              <Grid item textAlign="center" style={{ background: 'white' }}>
                Center
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={3}
              justifyContent="flex-end" // horizontal, along the main axis
              alignItems="end" // vertical, along the cross axis
              //alignContent // only works flex-wrap is set to either wrap or wrap-reverse (NOT the default setting)
              style={{ height: 100, border: '1px solid black' }}
            >
              <div style={{ background: 'white' }}>End</div>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {/*
      <Box>
        <Text variant="h1">Grid 2</Text>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Item>xs=2</Item>
          </Grid>
          <Grid item xs={2}>
            <Item>xs=2</Item>
          </Grid>
          <Grid item xs={2}>
            <Item>xs=2</Item>
          </Grid>
          <Grid item xs={2}>
            <Item>xs=2</Item>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Text variant="h1">Grid 3</Text>
        <Grid item container spacing={4}>
          <Grid item container xs={3}>
            <Grid item xs={3}>
              <Item>xs=8</Item>
            </Grid>
            <Grid item xs={3}>
              <Item>xs=8</Item>
            </Grid>
            <Grid item xs={8}>
              <Item>xs=8</Item>
            </Grid>
            <Grid item xs={8}>
              <Item>xs=8</Item>
            </Grid>
          </Grid>
          <Grid item container xs={3}>
            <Grid item xs={8}>
              <Item>xs=8</Item>
            </Grid>
            <Grid item xs={8}>
              <Item>xs=8</Item>
            </Grid>
            <Grid item xs={8}>
              <Item>xs=8</Item>
            </Grid>
            <Grid item xs={8}>
              <Item>xs=8</Item>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Text variant="h1">Grid 4</Text>
        <Grid container xs={12}>
          <Grid item xs={2}>
            <Text variant="body1">Grid item 1</Text>
          </Grid>
          <Grid item xs={2}>
            <Text variant="body1">Grid item 2</Text>
          </Grid>
          <Grid item xs={2}>
            <Text variant="body1">Grid item 3</Text>
          </Grid>
          <Grid item xs={2}>
            <Text variant="body1">Grid item 4</Text>
          </Grid>
          <Grid item xs={2}>
            <Text variant="body1">Grid item 5</Text>
          </Grid>
          <Grid item xs={2}>
            <Text variant="body1">Grid item 6</Text>
            <Grid item container xs={8}>
              <Grid item xs={8}>
                <Text variant="body1"></Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    */}
    </Grid>
  )
}
