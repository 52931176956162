import React, { useState, useEffect } from 'react'
import useChatModels from 'hooks/chat/useChatModels'
import CircularProgress from '@mui/material/CircularProgress'
import { Model } from 'openai'
import { Box } from '@mui/material'

export const MainModelsForm: React.FC = () => {
  const [chatResponse, setChatResponse] = useState<Model[]>([])
  const [submittedValue, setSubmittedValue] = useState<string>('')

  const { result, loading, error } = useChatModels(submittedValue)

  useEffect(() => {
    setChatResponse(result || [])
  }, [result])

  console.log('chatResponse: ', chatResponse)

  const handleSubmit = (value: string) => {
    setChatResponse([])
    setSubmittedValue(value)
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', padding: '4rem 0 4rem 0' }}>
        Currently avaialable OpenAI ChatGPT Models
      </div>
      {loading && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
      {chatResponse && (
        <div>
          {chatResponse.map((model, key) => (
            <Box
              key={key}
              width="30rem"
              style={{ backgroundColor: 'white', border: '1px solid black' }}
            >
              <div key={key}>
                <p>
                  <b>{model.id}</b>
                </p>
                <p>{new Date(model.created * 1000).toLocaleDateString()}</p>
                <p>{model.owned_by}</p>
                <p>{model.object}</p>
              </div>
            </Box>
          ))}
        </div>
      )}
      {error && <div>An error has occured - please try again.</div>}
    </div>
  )
}
