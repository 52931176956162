import React, { FC } from 'react'
import { Text } from 'components/Shared'

export const Privacy: FC = () => {
  return (
    <>
      <Text variant="h1">Privacy Policy</Text>

      <p>
        At Laptop Shed, accessible from https://www.laptopshed.com/, one of our main priorities is
        the privacy of our visitors. This Privacy Policy document contains types of information that
        is collected and recorded by Laptop Shed and how we use it.
      </p>

      <h2>Log Files</h2>

      <p>
        Laptop Shed follows a standard procedure of using log files. These files log visitors when
        they visit websites. All hosting companies do this and a part of hosting services analytics.
        The information collected by log files include internet protocol (IP) addresses, browser
        type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and
        possibly the number of clicks. These are not linked to any information that is personally
        identifiable. The purpose of the information is for analyzing trends, administering the
        site, tracking users movement on the website, and gathering demographic information.
      </p>

      <h2>Cookies and Web Beacons</h2>

      <p>Laptop Shed does not use cookies.</p>

      <h2>Our Partners</h2>

      <p>
        Some of the advertisers and/or partners on our site may use cookies and web beacons. Our
        advertising partners are listed below. Each of our advertising partners has their own
        Privacy Policy for their policies on user data. For easier access, we hyperlinked to their
        Privacy Policies below.
      </p>

      <ul>
        <li>
          <p>
            Amazon &nbsp;
            <a
              target="_blank"
              href="https://www.amazon.com/gp/help/customer/display.html?nodeId=GX7NJQ4ZB8MHFRNJ"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </p>
        </li>
      </ul>

      <h2>Third Party Privacy Policies</h2>

      <p>
        Laptop Shed&apos;s Privacy Policy does not apply to other advertisers or websites. Thus, we
        are advising you to consult the respective Privacy Policies of these third-party ad servers
        for more detailed information. It may include their practices and instructions about how to
        opt-out of certain options.{' '}
      </p>

      <p>
        You can choose to disable cookies through your individual browser options. To know more
        detailed information about cookie management with specific web browsers, it can be found at
        the browsers respective websites.
      </p>

      <h2>Online Privacy Policy Only</h2>

      <p>
        This Privacy Policy applies only to our online activities and is valid for visitors to our
        website with regards to the information that they shared and/or collect in Laptop Shed. This
        policy is not applicable to any information collected offline or via channels other than
        this website.
      </p>

      <h2>Consent</h2>

      <p>
        By using our website, you hereby consent to our Privacy Policy and agree to its Terms and
        Conditions.
      </p>
    </>
  )
}
