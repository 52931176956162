import React, { useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'

interface FormProps {
  onSubmit: (value: string) => void
  apiUrl: string
  label: string
  inputInstructions: string
}

export const CodeForm: React.FC<FormProps> = ({ apiUrl, label, inputInstructions, onSubmit }) => {
  const [value, setValue] = useState<string>('')
  const [dropdownValue, setDropdownValue] = useState<string>('animal')

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSubmit(value)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            id="value"
            label={label}
            variant="outlined"
            value={value}
            onChange={handleChange}
            helperText={inputInstructions}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
