import axios, { AxiosError, CancelToken } from 'axios'
import { mock_devices } from 'constants/data/mockDevices'
import { Device } from 'types'

const BASE_URL = (process.env.REACT_APP_SSP_API_BASE_URL || '/') + 'devices' // TODO: update dynamic endpoint from config service
const NODE_ENV = process.env.NODE_ENV
const USE_MOCK_DEVICES = process.env.REACT_APP_USE_MOCK_DEVICES === 'true'

const getAllDevices = async (cancelToken: CancelToken): Promise<Device[]> => {
  const { data } =
    USE_MOCK_DEVICES && NODE_ENV === 'development'
      ? { data: mock_devices }
      : await axios.get(`${BASE_URL}`).catch((error: AxiosError) => {
          if (error instanceof Error) {
            console.error('getAllDevices error: ', error)
          }
          return { data: [] }
        })
  return data
}

const getDeviceById = async (id: number): Promise<Device> => {
  const { data } =
    USE_MOCK_DEVICES && NODE_ENV === 'development'
      ? { data: mock_devices.find((device) => device.id === id) }
      : await axios.get(`${BASE_URL}/${id}`).catch((error: AxiosError) => {
          if (error instanceof Error) {
            console.error('getDeviceById error: ', error)
          }
          return { data: {} }
        })
  return data
}

const updateDeviceById = async (body: Device): Promise<Device> => {
  const { data } =
    USE_MOCK_DEVICES && NODE_ENV === 'development'
      ? { data: body }
      : await axios.put(`${BASE_URL}/${body.id}`, body)
  return data
}

export const deviceService = {
  getAllDevices,
  getDeviceById,
  updateDeviceById,
}
