import React from 'react'
import { Text } from 'components/Shared'

export const Navigation: React.FC = () => {
  return (
    <>
      <Text variant="h1" style={{ padding: '0 0 4rem 0' }}>
        Navigation
      </Text>
    </>
  )
}
