import { AssignmentStatus, Device, DevicesTabData } from 'types'

const getTabData = (devices: Device[]): DevicesTabData => {
  console.log('getTabData - devices: ', devices)
  const tabFilteredData = {
    total: devices.length,
    assigned: devices?.filter((device) => device.assignmentStatus === AssignmentStatus.Assigned)
      .length,
    unassigned: devices?.filter((device) => device.assignmentStatus === AssignmentStatus.Unassigned)
      .length,
  }
  return tabFilteredData
}
export { getTabData }
