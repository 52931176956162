import { itemService } from 'services/ItemService'
import { Item } from 'types'
import { UseQueryResult, useQuery } from 'react-query'

/**
 * StaleTime: The duration until a query transitions from fresh to stale. As long as the query is fresh,
 * data will always be read from the cache only - no network request will happen! If the query is stale
 * (which per default is: instantly), you will still get data from the cache, but a background refetch can happen under certain conditions. (mount/unmount)
 *
 * CacheTime: The duration until inactive queries will be removed from the cache.
 * This defaults to 5 minutes. Queries transition to the inactive state as soon as
 * there are no observers registered, so when all components which use that query have unmounted.
 *
 */
const useAllItems = (): UseQueryResult<void, Item[]> => {
  //console.log('Hook useAllItems entry (evaulate stale time)...')

  return useQuery({
    queryKey: ['myitems'],
    queryFn: async () =>
      itemService
        .getAllItems()
        .then((items) => {
          return items
        })
        .catch((e) => {
          console.error('Error:', e)
        }),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 60 * (60 * 1000), // 60 mins
    //staleTime: 30000, //Data is marked stale after 30 seconds - if stale, the next (active) data request will be a network API call, otherwise the cache can still be used
    //refetchInterval: 30000, //Data will be actively refetched every 30 seconds using a network API call, no matter what
  })
}

export default useAllItems
