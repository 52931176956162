import React, { FC } from 'react'
import { Button as MuiButton, ButtonProps, styled } from '@mui/material'
import { constants } from 'styles'

interface MyButtonProps {
  border: string
  color: string
  textColor: string
  textSize: string
  children?: React.ReactNode
  height: string
  onClick: () => void
  radius: string
  width: string
  disabled: boolean
}

//inferred prop type (MyButtonProps), and inferred return type (ReactElement)
export const CustomButton4: React.FC<MyButtonProps> = ({
  border,
  color,
  textColor,
  textSize,
  children,
  height,
  onClick,
  radius,
  width,
  disabled,
}) => {
  return (
    <MuiButton
      onClick={onClick}
      variant="outlined"
      disabled={disabled}
      sx={{
        '&:hover': { backgroundColor: 'darkGrey' },
        backgroundColor: color,
        color: textColor,
        fontSize: textSize,
        border,
        borderRadius: radius,
        height,
        width,
      }}
    >
      {children}
    </MuiButton>
  )
}
