import React, { useState } from 'react'
import { Text } from 'components/Shared'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

export const Forms: React.FC = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  return (
    <>
      <Text variant="h1" style={{ padding: '0 0 4rem 0' }}>
        Forms
      </Text>

      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField required id="outlined-required" label="Required" defaultValue="Hello World" />
          <TextField disabled id="outlined-disabled" label="Disabled" defaultValue="Hello World" />
          <TextField
            id="outlined-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
          />
          <TextField
            id="outlined-read-only-input"
            label="Read Only"
            defaultValue="Hello World"
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <div>
          <TextField
            label="Full Name"
            margin="normal"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />{' '}
          <TextField
            label="Email Address"
            margin="normal"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
          <TextField
            multiline
            rows="4"
            label="Message"
            margin="normal"
            variant="outlined"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
          />
        </div>
      </Box>
    </>
  )
}
