import { DiskDto } from 'types/Disk'

export const mock_disks: DiskDto[] = [
  {
    asin: 'B08QBJ2YMG',
    detailPageURL: 'https://amzn.to/4abKh8M',
    itemInfo: {
      features: {
        displayValues: ['Samsung 870 EVO SATA III SSD 1TB 2.5” Internal', '1TB RAM'],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Samsung 870 EVO SATA III SSD',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '89.99',
            currency: 'USD',
            displayAmount: '$89.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B09ZYQ84CM',
    detailPageURL: 'https://amzn.to/4ahc47C',
    itemInfo: {
      features: {
        displayValues: ['Western Digital 1TB WD Blue SA510 SATA Internal SSD', '1TB RAM'],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Western Digital 1TB WD Blue SATA SSD',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '74.99',
            currency: 'USD',
            displayAmount: '$74.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0CK2TC9XQ',
    detailPageURL: 'https://amzn.to/4cocTgH',
    itemInfo: {
      features: {
        displayValues: ['Crucial T500 2TB Gen4 NVMe M.2 Internal Gaming SSD', '2TB RAM'],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Crucial T500 2TB Gen4 Gaming SSD',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '152.09',
            currency: 'USD',
            displayAmount: '$152.09',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0B7CMZ3QH',
    detailPageURL: 'https://amzn.to/4cgKCsx',
    itemInfo: {
      features: {
        displayValues: ['Western Digital WD_BLACK 2TB Internal SSD', '2TB RAM'],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Western Digital WD_BLACK 2TB Internal SSD',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '156.97',
            currency: 'USD',
            displayAmount: '$156.97',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B09QVD9V7R',
    detailPageURL: 'https://amzn.to/4ahiXFW',
    itemInfo: {
      features: {
        displayValues: ['SK Hynix Platinum P41 2TB PCIe Gen4 Internal Gaming SSD,', '2TB RAM'],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'SK Hynix Platinum 2TB Gen4 Gaming SSD',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '139.99',
            currency: 'USD',
            displayAmount: '$139.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0CHHFR1LG',
    detailPageURL: 'https://amzn.to/4a2wbXA',
    itemInfo: {
      features: {
        displayValues: ['SAMSUNG 990 PRO w/Heatsink SSD 4TB PCIe Gen4 NVMe M.2 2280', '4TB RAM'],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'SAMSUNG 990 PRO w/Heatsink SSD 4TB',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '334.99',
            currency: 'USD',
            displayAmount: '$334.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B09K3PSWD2',
    detailPageURL: 'https://amzn.to/3VqKuAK',
    itemInfo: {
      features: {
        displayValues: [
          'Kingston FURY Renegade 4TB PCIe Gen 4.0 NVMe M.2 Internal Gaming SSD',
          '4TB RAM',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Kingston FURY Renegade 4TB PCIe Gen 4.0 NVMe M.2',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '403.55',
            currency: 'USD',
            displayAmount: '$403.55',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0BZMK1LWS',
    detailPageURL: 'https://amzn.to/4abYuTa',
    itemInfo: {
      features: {
        displayValues: [
          'Kingston NV2 4TB M.2 2280 NVMe Internal SSD',
          'PCIe 4.0 Gen 4x4',
          'Up to 3500 MB/s',
          '4TB RAM',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Kingston NV2 4TB M.2 2280 NVMe Internal SSD',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '254.99',
            currency: 'USD',
            displayAmount: '$254.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0CGKY9K2Y',
    detailPageURL: 'https://amzn.to/4coCW7C',
    itemInfo: {
      features: {
        displayValues: [
          'Lexar 4TB NM790 SSD with Heatsink PCIe Gen4 NVMe M.2 2280 Internal Solid State Drive',
          'Up to 7400/6500 MB/s Read/Write',
          '4TB RAM',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Lexar 4TB NM790 SSD with Heatsink',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '299.99',
            currency: 'USD',
            displayAmount: '$299.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0BV92XSMG',
    detailPageURL: 'https://amzn.to/4a5U4O0',
    itemInfo: {
      features: {
        displayValues: [
          'HP FX900 Pro 4TB NVMe Gen 4 Gaming SSD ',
          'PCIe 4.0, 16 Gb/s, M.2 2280',
          '3D TLC NAND Internal SSD with DRAM Cache Up to 7400 MB/s',
          '4TB RAM',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'HP FX900 Pro 4TB NVMe Gen 4 Gaming SSD',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '224.99',
            currency: 'USD',
            displayAmount: '$224.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
]
