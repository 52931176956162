import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { Color, FontStyleProperty, FontWeightProperty } from 'csstype'

interface TextStyleProps {
  fontStyle?: FontStyleProperty
  fontWeight?: FontWeightProperty
  colorOverride?: Color
}

const useTextStyles = makeStyles<Theme, TextStyleProps>(() => ({
  fontOverride: (props) => ({
    fontStyle: props.fontStyle,
    fontWeight: props.fontWeight,
    color: props.colorOverride,
  }),
}))

export default useTextStyles
