import React from 'react'
import { Text } from 'components/Shared'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { MyCard, CardProps } from './MyCard'

export const Cards: React.FC = () => {
  const bullet = (
    <Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
      *
    </Box>
  )
  const cardList: Array<CardProps> = [
    {
      title: 'Card 1',
      definition: 'definition 1',
      cardType: 'adjective 1',
      description: 'description 1',
      buttonText: 'Learn Mo',
      bullet: (
        <div>
          be{bullet}nev{bullet}o{bullet}lent
        </div>
      ),
    },
    {
      title: 'Card 2',
      definition: 'definition 2',
      cardType: 'adjective 2',
      description: 'description 2',
      buttonText: 'Learn Less',
    },
    {
      title: 'Card 3',
      definition: 'definition 3',
      cardType: 'adjective 3',
      description: 'description 3',
      buttonText: 'Learn None',
    },
  ]

  return (
    <>
      <Text variant="h1" style={{ padding: '0 0 4rem 0' }}>
        Cards
      </Text>

      <Box>
        <Text variant="h1">Custom Card List</Text>
        {cardList.map((card, key) => (
          <MyCard {...card} key={key} />
        ))}
      </Box>

      <Box>
        <Text variant="h1">Single Card</Text>
        <Card variant="elevation" sx={{ maxWidth: 200 }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Word of the Day
            </Typography>
            <Typography variant="h5" component="div">
              be{bullet}nev{bullet}o{bullet}lent
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              adjective
            </Typography>
            <Typography variant="body2">
              well meaning and kindly.
              <br />
              {'"a benevolent smile"'}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small">Learn More</Button>
          </CardActions>
        </Card>
      </Box>
    </>
  )
}
