import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react'
import { Paper, Tab, Tabs } from '@mui/material'
import useAllDevices from 'hooks/devices/useAllDevices'
import { Pill, Table, Text } from 'components/Shared'
import { getTabData } from './util'
import { colDefs } from 'constants/data/deviceGridColumnDefs'
import { AssignmentStatus, Device, DevicesTabData } from 'types'

const labelNode = (label: string, value: number) => (
  <>
    {label}
    <Pill label={value} size="small" />
  </>
)

export const DeviceTable = (): JSX.Element => {
  //export default function Devices(): ReactElement {
  const [devices, setDevices] = useState<Device[]>([])
  const [filteredDevices, setFilteredDevices] = useState<Device[]>([])
  const [loading, setLoading] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [tabData, setTabData] = useState<DevicesTabData>({
    total: 0,
    assigned: 0,
    unassigned: 0,
  })

  const devicesResponse = useAllDevices()

  useEffect(() => {
    const { result, loading } = devicesResponse
    setDevices(result || [])
    setFilteredDevices(result || [])
    setLoading(loading)
    console.log('DemoTable - devicesResponse: ', devicesResponse)
    console.log('DemoTable - devicesResponse.result: ', devicesResponse.result)
    setTabData(getTabData(devicesResponse.result || []))
  }, [devicesResponse.result])

  const handleTabChange = (event: SyntheticEvent<Element, Event>, newValue: number) => {
    switch (newValue) {
      case 1:
        setFilteredDevices(
          devices.filter((device) => device.assignmentStatus === AssignmentStatus.Assigned)
        )
        break
      case 2:
        setFilteredDevices(
          devices.filter((device) => device.assignmentStatus === AssignmentStatus.Unassigned)
        )
        break
      default:
        setFilteredDevices(devices)
    }
    setTabValue(newValue)
  }

  return (
    <div>
      <Text variant="h1" style={{ padding: '0 0 2rem 0' }}>
        Devices found - filter to find specific devices.
      </Text>
      <Paper
        style={{ width: 'fit-content', overflow: 'hidden', margin: '3.2rem 0 1.6rem' }}
        elevation={0}
      >
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label={labelNode('All', tabData.total)} />
          <Tab label={labelNode('New', tabData.assigned)} />
          <Tab label={labelNode('Refurbished', tabData.unassigned)} />
        </Tabs>
      </Paper>
      <Table columnDefs={colDefs} rowData={filteredDevices} paginated={true} />
    </div>
  )
}
