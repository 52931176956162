import { Disk, DisksTabData } from 'types'

const getTabData = (disks: Disk[]): DisksTabData => {
  const tabFilteredData = {
    total: disks.length,
    new: disks?.filter((item) => item.condition === 'New').length,
    renewed: disks?.filter((item) => item.condition === 'Renewed').length,
    apple: disks?.filter((item) => item.type === 'Mac OS').length,
    windows: disks?.filter((item) => item.type === 'Windows').length,
    price: disks?.filter((item) => Number(item.price) < 100).length,
  }
  return tabFilteredData
}
export { getTabData }
