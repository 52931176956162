import React, { useState, useEffect } from 'react'
import { CodeForm } from './CodeForm'
import useChatCodePrompt from 'hooks/chat/useChatCodePrompt'
import CircularProgress from '@mui/material/CircularProgress'

export const MainCodeForm: React.FC = () => {
  const apiUrl = 'https://your-api-url.com'

  const [chatResponse, setChatResponse] = useState<string>('')
  //const [loading, setLoading] = useState(false)
  const [submittedValue, setSubmittedValue] = useState<string>('')
  const [promptType, setPromptType] = useState<string>('')

  const label = 'Enter a code description:'
  const inputInstructions = 'Pick a language and type a description of the code you want to write.'

  const { result, loading, error } = useChatCodePrompt(submittedValue)

  useEffect(() => {
    //const { result, loading } = response
    setChatResponse(result || '')
    //setLoading(loading)
  }, [result])

  console.log('chatResponse: ', chatResponse)

  const handleSubmit = (value: string) => {
    setChatResponse('')
    setSubmittedValue(value)
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', padding: '4rem 0 2rem 0' }}>
        Use this model to generate a piece of code from a pre-defined description/requirements.
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', padding: '1rem 0 4rem 0' }}>
        Example: write a Java program to update a customer address in a database
      </div>
      <CodeForm
        apiUrl={apiUrl}
        label={label}
        inputInstructions={inputInstructions}
        onSubmit={handleSubmit}
      />
      {loading && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
      {chatResponse && (
        <div>
          <h4>Chat Response</h4>
          <pre>{chatResponse}</pre>
        </div>
      )}
      {error && <div>An error has occured - please update your prompt and try again.</div>}
    </div>
  )
}
