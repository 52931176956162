import { StringCellParams, Item, ItemColDefs, ToolTipGetterParams } from 'types'

import { LinkCellProps } from 'components/Shared/Table/CellRenderers/LinkCell'
import { ValueFormatterParams } from 'ag-grid-community'

export const colDefs: ItemColDefs[] = [
  {
    headerName: 'Price',
    field: 'price',
    width: 110,
    valueFormatter: (rowData: ValueFormatterParams): string => {
      const { data } = rowData
      return data.price || '---'
    },
    tooltipValueGetter: (): string => {
      return 'Best price found'
    },
  },
  {
    headerName: 'Title',
    field: 'asin',
    cellRenderer: 'linkCell',
    /*
    valueFormatter: (rowData: ValueFormatterParams): string => {
      const { data } = rowData
      return data.itemInfo.title.displayValue || '---'
    },
    */
    cellRendererParams: (rowData: StringCellParams<Item>): Partial<LinkCellProps> => {
      const { data } = rowData
      const displayValue = data.title
      const href = `${data.detailPageURL}`
      //const href = `https://www.amazon.com/dp/B0199980K4?tag=xyz-20&linkCode=ogi&language=en_US&th=1&psc=1`
      return { label: displayValue, href }
    },
  },
  {
    headerName: 'Condition',
    field: 'asin',
    valueFormatter: (rowData: ValueFormatterParams): string => {
      const { data } = rowData
      return data.condition || '---'
    },
    tooltipValueGetter: (): string => {
      return 'New, used, refurbished, etc'
    },
  },
  {
    headerName: 'ASIN',
    field: 'asin',
  },
]
