import axios, { AxiosError, CancelToken } from 'axios'
import { mock_special_items } from 'constants/data/mockSpecialItems'
import { Item, ItemDto } from 'types'
import { delay } from 'utils/delay'
import { normalizeItem } from 'utils/normalizeItem'

const SERVERLESS_ITEMS_URL = process.env.REACT_APP_SERVERLESS_ITEMS_URL
const NODE_ENV = process.env.NODE_ENV
const USE_MOCK_DEVICES = process.env.REACT_APP_USE_MOCK_DEVICES === 'true'

const getAllItems = async (): Promise<Item[]> => {
  /*
  const { data } =
    USE_MOCK_DEVICES && NODE_ENV === 'development'
      ? await delay({ data: mock_special_items })
      : await axios.get<ItemDto[]>(`${BASE_URL}`).catch((error: AxiosError) => {
          if (error instanceof Error) {
            console.error('getAllItems error: ', error)
          }
          return {
            data: [],
          }
        })
  */
  const { data } = { data: mock_special_items }
  return data.map(normalizeItem)
}

export const specialItemService = {
  getAllItems,
}
