import React, { FC } from 'react'
import { Button as MuiButton, ButtonProps, styled } from '@mui/material'
import { constants } from 'styles'

const MyThemeButton = styled(MuiButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  variant: 'outlined',
  '&:hover': {
    color: constants.colors.black,
    backgroundColor: theme.palette.secondary.main,
  },
}))

export interface ButtonCellProps extends ButtonProps {
  value: string
}

export const CustomButton1: FC<ButtonCellProps> = ({
  size,
  onClick,
  variant,
  value,
  color,
  disabled,
  children,
}) => {
  return (
    <MyThemeButton
      size={size}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      color={color}
    >
      {children}
    </MyThemeButton>
  )
}
