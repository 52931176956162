import { colors } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme, ListItemButton, styled } from '@mui/material'
import { constants } from '../../styles'

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  width: '16.8rem',
  height: '5rem',
  left: '0.5rem',
  top: '0.3rem',
  margin: '0.625rem',
  borderRadius: '0.75rem',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  '&:hover': {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.primary.main,
  },

  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
}))

//DEPRECATED - material-ui v4 makeStyles
/*
export const useNavStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: constants.drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: constants.drawerWidth,
    },
    drawerContainer: {
      overflow: '', //'auto',
    },
    facility: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconSize: {
      width: 16,
      height: 16,
    },
    listItemButton: {
      width: '19rem',
      height: '5rem',
      left: '0.5rem',
      top: '0.3rem',
      margin: '0.625rem',
      borderRadius: '0.75rem',
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
      '&:hover': {
        backgroundColor: theme.palette.grey[500],
        color: theme.palette.primary.main,
      },
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
  })
  )
  */
