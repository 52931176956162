import React, { useState } from 'react'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { Send } from '@mui/icons-material'
import Grid from '@mui/material/Grid'

interface FormProps {
  onSubmit: (value: string) => void
  onPrompt: (value: string) => void
  apiUrl: string
  label: string
  inputInstructions: string
}

export const ChatForm: React.FC<FormProps> = ({
  apiUrl,
  label,
  inputInstructions,
  onSubmit,
  onPrompt,
}) => {
  const [value, setValue] = useState<string>('')
  const [dropdownValue, setDropdownValue] = useState<string>('animal')

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    //const response = await axios.post(apiUrl, { value })
    console.log(value)
    onSubmit(value)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const handleDropdown = (value: string) => {
    setDropdownValue(value)
    onPrompt(value)
    setValue('')
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputLabel id="test-select-label">
            Please select the type of Chat Prompt you want to use:
          </InputLabel>
          <FormControl>
            <Select
              value={dropdownValue}
              onChange={(event) => handleDropdown(event.target.value as string)}
            >
              <MenuItem value="animal">Animal</MenuItem>
              <MenuItem value="pirate">Pirate</MenuItem>
              <MenuItem value="mrrobot">Mr Robot</MenuItem>
              <MenuItem value="dan">DAN</MenuItem>
              <MenuItem value="free">Free</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="value"
            label={label}
            variant="outlined"
            value={value}
            onChange={handleChange}
            helperText={inputInstructions}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
