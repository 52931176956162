import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react'
import { Paper, Tab, Tabs } from '@mui/material'
import useAllDisks from 'hooks/items/useAllDisksCached'
import { Pill, Table, Text } from 'components/Shared'
import { getTabData } from './util'
import { colDefs } from 'constants/data/diskGridColumnDefs'
import { Disk, ItemsTabData } from 'types'

const labelNode = (label: string, value: number) => (
  <>
    {label}
    <Pill label={value} size="small" />
  </>
)

export const DiskTable = (): JSX.Element => {
  //export default function DiskTable(): ReactElement {
  const [disks, setDisks] = useState<Disk[]>([])
  const [filteredDisks, setFilteredDisks] = useState<Disk[]>([])
  const [loading, setLoading] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [tabData, setTabData] = useState<ItemsTabData>({
    total: 0,
    new: 0,
    renewed: 0,
    apple: 0,
    windows: 0,
    price: 0,
  })

  const itemsResponse = useAllDisks()
  useEffect(() => {
    const { data, isLoading, isError, isStale } = itemsResponse
    setDisks(data || [])
    setFilteredDisks(data || [])
    setLoading(isLoading)
    setTabData(getTabData(itemsResponse.data || []))
  }, [itemsResponse.data])

  const handleTabChange = (event: SyntheticEvent<Element, Event>, newValue: number) => {
    switch (newValue) {
      case 1:
        setFilteredDisks(disks.filter((disk) => disk.condition === 'New'))
        break
      case 2:
        setFilteredDisks(disks.filter((disk) => disk.condition === 'Renewed'))
        break
      case 3:
        setFilteredDisks(disks.filter((disk) => Number(disk.price) < 100))
        break
      default:
        setFilteredDisks(disks)
    }
    setTabValue(newValue)
  }

  return (
    <div>
      <Text variant="body1">
        Current SSD Drive listings from the Amazon Marketplace. Use the table to filter by column or
        by groups using the tabs above the table.
      </Text>
      <Paper
        style={{ width: 'fit-content', overflow: 'hidden', margin: '3.2rem 0 1.6rem' }}
        elevation={0}
      >
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label={labelNode('All', tabData.total)} />
          <Tab label={labelNode('New', tabData.new)} />
          <Tab label={labelNode('Renewed', tabData.renewed)} />
          <Tab label={labelNode('Under $100', tabData.price)} />
        </Tabs>
      </Paper>
      <Table columnDefs={colDefs} rowData={filteredDisks} paginated={true} />
      <h1 style={{ color: '#F0F2F4' }}>
        amazon laptop prices amazon laptops dell lenovo apple macbook hp asus acer samsung microsoft
        msi intel celeron amd ryzen chromebook
      </h1>
    </div>
  )
}
