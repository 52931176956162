import { Disk, Features, DiskDto } from 'types'

type Specs = 'type' | 'size'

const specsMap: Record<Specs, string> = {
  type: '',
  size: '',
}

const parseFeatures = (features: Features): Record<Specs, string> => {
  if (!features) {
    return specsMap
  }

  const { displayValues } = features

  const mainTitle = displayValues[0]

  if (mainTitle.includes('NVMe')) {
    specsMap['type'] = 'NVMe'
  } else {
    specsMap['type'] = 'SATA'
  }

  const sizeStr = displayValues[displayValues.length - 1]?.replace(/\D/g, '')
  specsMap['size'] = sizeStr

  return specsMap
}

export const normalizeDisk = (rawDisk: DiskDto): Disk => {
  const { asin, detailPageURL, itemInfo, offers } = rawDisk
  const features = parseFeatures(itemInfo.features)

  return {
    asin,
    title: itemInfo.title.displayValue,
    price: offers.summaries[0].highestPrice.amount,
    type: features['type'],
    size: features['size'],
    condition: offers.summaries[0].condition.value,
    detailPageURL,
  }
}
