import { StringCellParams, Memory, MemoryColDefs, ToolTipGetterParams } from 'types'

import { LinkCellProps } from 'components/Shared/Table/CellRenderers/LinkCell'
import { ValueFormatterParams } from 'ag-grid-community'

const numberSort = (num1: number, num2: number) => {
  return num1 - num2
}

export const colDefs: MemoryColDefs[] = [
  {
    headerName: 'Brand',
    field: 'title',
    cellRenderer: 'linkCell',
    width: 400,
    cellRendererParams: (rowData: StringCellParams<Memory>): Partial<LinkCellProps> => {
      const { data } = rowData
      const displayValue = data.title
      const href = `${data.detailPageURL}`
      return { label: displayValue, href }
    },
  },
  {
    headerName: 'Price',
    field: 'price',
    sort: 'asc',
    comparator: numberSort,
    valueFormatter: (rowData: ValueFormatterParams): string => {
      const { data } = rowData
      return '$' + data.price || '---'
    },
    tooltipValueGetter: (): string => {
      return 'Best price found'
    },
  },
  {
    headerName: 'Type',
    field: 'type',
  },
  {
    headerName: 'Size',
    field: 'size',
    comparator: numberSort,
    valueFormatter: (rowData: ValueFormatterParams): string => {
      const { data } = rowData
      if (data.size) {
        return data.size + ' GB'
      }
      return ''
    },
  },
  {
    headerName: 'Condition',
    field: 'condition',
    valueFormatter: (rowData: ValueFormatterParams): string => {
      const { data } = rowData
      return data.condition || '---'
    },
    tooltipValueGetter: (): string => {
      return 'New, used, refurbished, etc'
    },
  },
]
