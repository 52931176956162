import { useRef, useEffect } from 'react'
import axios, { CancelTokenSource, CancelToken } from 'axios'

interface IUseCancelToken {
  newCancelToken: () => CancelToken
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isCancel: (value: string) => boolean
  currentToken?: CancelTokenSource
}

/**
 * When a hook that uses Axios unmounts, we need to cancel any potentially
 * ongoing Axios calls that result in a state update on success / fail.
 *
 * @returns {IUseCancelToken}
 * newCancelToken - used to generate the cancel token sent in the Axios request.
 * isCancel - used to check if error returned in response is a cancel token error.
 * currentToken - used to ca
 */
export const useCancelToken = (): IUseCancelToken => {
  const { CancelToken, isCancel } = axios
  const axiosSource = useRef<CancelTokenSource>()
  const newCancelToken = (): CancelToken => {
    axiosSource.current = CancelToken.source()
    return axiosSource.current?.token
  }

  useEffect(
    () => () => {
      if (axiosSource.current) axiosSource.current.cancel()
    },
    []
  )

  return { newCancelToken, isCancel, currentToken: axiosSource.current }
}
