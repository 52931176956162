import { Item, ItemsTabData } from 'types'

const getTabData = (items: Item[]): ItemsTabData => {
  const tabFilteredData = {
    total: items.length,
    new: items?.filter((item) => item.condition === 'New').length,
    renewed: items?.filter((item) => item.condition === 'Renewed').length,
    apple: items?.filter((item) => item.type === 'Mac OS').length,
    windows: items?.filter((item) => item.type === 'Windows').length,
    price: items?.filter((item) => Number(item.price) < 100).length,
  }
  return tabFilteredData
}
export { getTabData }
