import React, { ReactElement } from 'react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query'

import { appTheme } from './styles/ThemeService'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Routes from './components/Routes'

//console.log('appTheme: ', appTheme)

const queryClient = new QueryClient()

function handelRightClick(event: { preventDefault: () => void }) {
  event.preventDefault()
}

export default function App(): ReactElement {
  document.addEventListener('contextmenu', handelRightClick)

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={appTheme}>
          <CssBaseline>
            <Router>
              <Routes />
            </Router>
          </CssBaseline>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  )
}
