import React, { ReactElement } from 'react'
import { Text } from 'components/Shared'

export default function Footer() {
  //}: ReactElement {
  return (
    <div>
      <Text variant="body1" style={{ padding: '2rem 0 0 0' }}>
        The service we offer is part of the Amazon Associate program, where money can be earned from
        the purchases found on this website. The prices listed for each item are a reflection of the
        listed Amazon price, and is not altered in any way and may change daily. The price and
        features will be consistent with the products that are available on the Amazon website. We
        reserve the right to publish or remove any listings that are featured as a part of the
        Amazon Associate program, and any abuse will be logged and reported.
      </Text>
    </div>
  )
}
