import { brix } from './bbyUXThemeDefinitions'
//import { custom } from './customUXThemeDefinitions'

const constants = {
  headerHeight: '6.4rem',
  headerLogo: {
    width: '9.3rem',
    height: '4.3rem',
  },
  drawerWidth: '16rem',
  drawerWidthCondensed: '5.2rem',
  tabBorderRadius: '0.4rem',
  tabPadding: {
    default: '0.4rem 1.2rem',
    small: '0 0.9rem',
  },
  buttonPadding: {
    medium: '1.3rem 1.2rem',
    small: '0.8rem',
    large: '1.8rem 1.6rem',
  },
  pillHeight: {
    small: '1.6rem',
    default: '2.0rem',
  },
  buttonMinWidth: '9.6rem',
  border: (width: number, color: string): string => `0.${width}rem solid ${color}`,
  buttonLineHeight: brix.typography.variants.body.lineHeight,
  radioBoxMinHeight: '7rem',
  radioBoxWidth: '36.8rem',
  radioBoxLabelMaxWidth: '28.9rem',
  radioBoxSpacing: {
    root: '1.6rem',
    column: '2rem 0 0',
    row: '2rem 1.6rem 0 0',
    rowLastChild: '2rem 0 0 0',
    titleBottom: '0.4rem',
    none: '0',
  },
  colors: {
    white: brix.colors.graySkull.white,
    black: brix.colors.graySkull.techBlack,
    primaryMain: brix.colors.humanBlue,
    primaryDark: brix.colors.darkBlue,
    primaryLight: brix.colors.lightBlue,
    primaryHighlight: brix.colors.highlightBlue,
    secondaryMain: brix.colors.buttonYellow,
    secondaryDark: brix.colors.darkYellow,
    secondaryLight: brix.colors.techYellow,
    success: brix.colors.successGreen,
    successLight: brix.colors.successGreenLight,
    warn: brix.colors.caution,
    warnLight: brix.colors.cautionLight,
    error: brix.colors.valueRed,
    errorLight: brix.colors.valueRedLight,
    neutral: brix.colors.graySkull.darkGray,
    neutralLight: brix.colors.neutralLight,
    neutralDark: brix.colors.graySkull.hintGray,
    textPrimary: brix.colors.graySkull.techBlack,
    textSecondary: brix.colors.graySkull.techGray,
    grey: {
      // MuiColor interface @mui/material/index.d.ts => Color
      50: brix.colors.graySkull.white,
      100: brix.colors.highlightBlue,
      200: brix.colors.graySkull.backgroundWhite,
      300: brix.colors.graySkull.techWhite,
      400: brix.colors.graySkull.lightGray,
      500: brix.colors.graySkull.techGray,
      600: brix.colors.graySkull.hintGray,
      700: brix.colors.graySkull.darkGray,
      800: brix.colors.graySkull.techBlack,
      900: brix.colors.graySkull.black,
      A100: brix.colors.graySkull.backgroundWhite,
      A200: brix.colors.graySkull.techWhite,
      A400: brix.colors.graySkull.techGray,
      A700: brix.colors.graySkull.techBlack,
    },
  },
  typography: {
    h1: brix.typography.variants.headline,
    h2: brix.typography.variants.pageTitle,
    h3: brix.typography.variants.title,
    h4: brix.typography.variants.heroTitle,
    h5: brix.typography.variants.sectionTitle,
    h6: brix.typography.variants.largeBody,
    subtitle1: brix.typography.variants.disclaimer,
    subtitle2: brix.typography.variants.navigation,
    body1: brix.typography.variants.body,
    body2: brix.typography.variants.smallBody,
    caption: {},
    button: {},
    overline: {},
  },
}

export { constants }
