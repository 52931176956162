import { useEffect, useState } from 'react'
import { chatService } from 'services/ChatService'
import { AsyncHookResponse } from 'types/'

const useChatPromptByType = (type: string, value: string): AsyncHookResponse<string> => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [result, setResult] = useState<string>()

  const fetchAIResponse = (type: string, value: string) => {
    setLoading(true)
    setError(false)

    chatService
      .getResponseByPromptType(type, value)
      .then((promptResponse) => {
        setResult(promptResponse)
        setLoading(false)
      })
      .catch((e) => {
        console.error('Error:', e)
        setError(true)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (type && value) {
      fetchAIResponse(type, value)
    }
  }, [type, value])

  return { result, loading, error }
}

export default useChatPromptByType
