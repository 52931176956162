import React from 'react'
import { Text } from 'components/Shared'
import { Grid, TextareaAutosize } from '@mui/material'

export const ThemeMapping: React.FC = () => {
  return (
    <>
      <Text variant="h1" style={{ padding: '0 0 4rem 0' }}>
        Theme Definition Mappings
      </Text>
      <Text variant="body1"></Text>
      <Grid item container direction={'column'}>
        <Grid item container xs={4} spacing={2}>
          <Grid item xs={4}>
            Map the UX Design System colors and typography values to our Theme service so we can
            start building our application Theme by defining our color palette, font family, and
            typography variants.
          </Grid>
        </Grid>
        <Grid item container xs={4} spacing={2}>
          <Grid item xs={4}>
            <TextareaAutosize
              maxRows={100}
              readOnly={true}
              aria-label="maximum height"
              placeholder="Maximum 4 rows"
              defaultValue="  colors: {
                humanBlue: #0046BE,
                darkBlue: #001E73,
                lightBlue: #2B5DF5,
                highlightBlue: #F1F8FF,
                buttonYellow: #FFE000,
                darkYellow: #FFCE00,
                techYellow: #FFF200,
                successGreen: #318000,
                successGreenLight: #EFF5EB,
                gsOrange: #E37222,
                caution: #A56300,
                cautionLight: #FBEADE,
                valueRed: #BB0628,
                valueRedLight: #F5DADF,
                neutralLight: #F1F1F2,
                graySkull: {
                  white: #FFFFFF,
                  backgroundWhite: #F0F2F4,
                  techWhite: #E0E6EF,
                  lightGray: #C5CBD5,
                  techGray: #959AA2,
                  hintGray: #70757D,
                  darkGray: #55555A,
                  techBlack: #1D252C,
                  black: #000000,
                },
              },
              typography: {
                fontFamily: Roboto,
                color: graySkull.techBlack,
                variants: {
                  headline: {
                    fontSize: 3.6rem,
                    lineHeight: 4.4rem,
                    fontWeight: 400,
                  },
                  pageTitle: {
                    fontSize: 2.4rem,
                    lineHeight: 3.0rem,
                    fontWeight: 400,
                  },
                  title: {
                    fontSize: 2.4rem,
                    lineHeight: 2.4rem,
                    fontWeight: 400,
                  },
                  heroTitle: {
                    fontSize: 2.0rem,
                    lineHeight: 2.4rem,
                    fontWeight: 400,
                  },
                  sectionTitle: {
                    fontSize: 1.6rem,
                    lineHeight: 2.0rem,
                    fontWeight: 400,
                  },
                  largeBody: {
                    fontSize: 1.6rem,
                    lineHeight: 2.0rem,
                    fontWeight: 400,
                  },
                  body: {
                    fontSize: 1.4rem,
                    lineHeight: 1.8rem,
                    fontWeight: 400,
                  },
                  smallBody: {
                    fontSize: 1.2rem,
                    lineHeight: 1.6rem,
                    fontWeight: 400,
                  },
                  disclaimer: {
                    fontSize: 1.1rem,
                    lineHeight: 1.4rem,
                    fontWeight: 400,
                  },
                  navigation: {
                    fontSize: 1.0rem,
                    lineHeight: 1.6rem,
                    fontWeight: 400,
                  },
                },
              },
            }
            "
              style={{ width: 600 }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
