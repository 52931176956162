import axios from 'axios'
import { mock_devices } from 'constants/data/mockDevices'
import { Todo } from 'types'

const BASE_URL = (process.env.REACT_APP_SSP_API_BASE_URL || '/') + 'devices' // TODO: update dynamic endpoint from config service
const SERVERLESS_TODOS_URL = 'https://zzq47cdod3.execute-api.us-east-1.amazonaws.com/dev/todos'
const NODE_ENV = process.env.NODE_ENV
const USE_MOCK_DEVICES = process.env.REACT_APP_USE_MOCK_DEVICES === 'true'

const getAllTodos = async (): Promise<Todo[]> => {
  const { data } = await axios.get(`${SERVERLESS_TODOS_URL}`)
  return data
}

export const awsGatewayService = {
  getAllTodos,
}
