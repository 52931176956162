import React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

export interface CardProps {
  title: string
  definition: string
  cardType: string
  description: string
  buttonText: string
  bullet?: JSX.Element
}

export const MyCard: React.FC<CardProps> = ({
  title,
  definition,
  cardType,
  description,
  buttonText,
  bullet,
}) => {
  return (
    <Card variant="elevation" sx={{ maxWidth: 200 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h5" component="div">
          {definition}
        </Typography>
        <Typography variant="h5" component="div">
          {bullet}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {cardType}
        </Typography>
        <Typography variant="body2">
          {description}
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">{buttonText}</Button>
      </CardActions>
    </Card>
  )
}
