enum PowerStatus {
  On = 'On',
  Off = 'Off',
  NotActivated = 'Not Activated',
  Unknown = 'Unknown',
}

enum AssignmentStatus {
  Assigned = 'Assigned',
  Unassigned = 'Unassigned',
  PendingReturn = 'Pending Return',
}

enum DeviceFlag {
  Broken = 'Broken',
}

export { PowerStatus, AssignmentStatus, DeviceFlag }
