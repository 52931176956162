import { Button as MuiButton, Typography } from '@mui/material'
import React, { ReactElement } from 'react'
import { Text } from 'components/Shared'
import { Grid, TextareaAutosize } from '@mui/material'

/*
  In most cases, TypeScript is smart and can infer the function's return type — JSX.Element, ReactElement, etc:

  Using React.FC
  Another way to define props is to import and use React's Functional Component type, FC for short.
  Using React.FC is more verbose, but does have some added benefits:.
  Explicit with its return type
  Provides type checking and autocomplete for static properties (i.e displayName, defaultProps)
  Provides an implicit definition of children:
  
  const ReactFCComponent: React.FC<{title:string}> = ({children, title}) => {
          return <div title={title}>{children}</div>
  }
  
  You can read more about the benefits of React.FC here

  Which should you use? The React community generally frowns upon using React.FC due to its verbosity, 
  and because its benefits aren't worth the trouble and difficulty to read.

*/
export default function Typescript(): ReactElement {
  interface containerProps {
    heading: string
    para: string
  }

  const container = (props: containerProps) => {
    return <></>
  }

  interface MyUserProps {
    first: string
    last: string
    middle?: string
  }

  const user1 = { first: 'string', last: 'string' }
  const user2: MyUserProps = { first: 'string', last: 'string' }

  // Simple yet effective
  // implicit return JSX.Element, ReactElement, etc
  const DisplayName1 = (props: MyUserProps) => {
    return (
      <div>
        ${props.first}, ${props.last}
      </div>
    )
  }

  // More flexible, destructuring
  const DisplayName2 = ({ first, last }: MyUserProps) /*: React.ReactElement*/ => {
    return (
      <div>
        ${first}, ${last}
      </div>
    )
  }

  //explicit function type declaration of FC
  const DisplayName3: React.FC<MyUserProps> = ({ first, last }) => {
    return (
      <div>
        ${first}, ${last}
      </div>
    )
  }

  //explicit function type declaration of FC, children come for free!
  const DisplayName4: React.FC<MyUserProps> = (children, { first, last }) => {
    return (
      <>
        <div>
          ${first}, ${last}, {children}
        </div>
        <div>{children}</div>{' '}
      </>
    )
  }

  const DisplayName5: React.FC<MyUserProps> = (user: { first: string; last: string }) => {
    return (
      <div>
        ${user.first}, ${user.last}
      </div>
    )
  }

  //return a string
  const displayName = (user: { first: string; last: string }): string => {
    return `${user.first}, ${user.last}`
  }

  interface VehicleProps {
    color: string
    cost: string
    make: string
    model: string
    year: string
    miles: string
    accidents?: boolean
  }

  const mustang: VehicleProps = {
    color: 'black',
    cost: '55,000',
    make: 'Ford',
    model: 'Mustang GT',
    year: '2017',
    miles: '27,000',
  }
  //const DisplayCar: React.FC<VehicleProps> = (props) => {
  const DisplayCar: React.FC<VehicleProps> = ({
    children,
    color,
    cost,
    make,
    model,
    year,
    accidents,
  }) => {
    return (
      <div>
        <Typography variant="h3">{children}</Typography>

        <Typography variant="h4">Year</Typography>
        <Typography variant="body1">{year}</Typography>
        <Typography variant="h4">Make</Typography>
        <Typography variant="body1">{make}</Typography>
        <Typography variant="h4">Model</Typography>
        <Typography variant="body1">{model}</Typography>
        <Typography variant="h4">Color</Typography>
        <Typography variant="body1">{color}</Typography>
        <Typography variant="h4">Cost</Typography>
        <Typography variant="body1">{cost}</Typography>
        <Typography variant="h4">Accidents</Typography>
        <Typography variant="body1">{accidents ? 'Yes' : 'None'}</Typography>
      </div>
    )
  }

  interface ButtonTestProps {
    color: string
    value?: string
  }
  // explicit return type
  const ButtonTest: React.FC<ButtonTestProps> = ({ children, color }) => {
    const bgColor = color === 'primary' ? 'green' : 'red'
    return <MuiButton variant="contained">{children}</MuiButton>
  }

  const ButtonTestTwo = (props: ButtonTestProps) => {
    return (
      <MuiButton
        style={{
          backgroundColor: props.color,
        }}
      >
        {props.value}
      </MuiButton>
    )
  }

  const ButtonTestThree: React.FC<ButtonTestProps> = ({ children }, props: ButtonTestProps) => {
    return (
      <MuiButton
        style={{
          backgroundColor: props.color,
        }}
      >
        {children}
      </MuiButton>
    )
  }

  interface MyComponentProps {
    className: string
  }
  interface MyComponentWithChildredProps {
    className: string
    children: JSX.Element
  }
  const MyComponent: React.FC<MyComponentProps> = ({ className, children }) => {
    // children is typed
    return <div className={className}>{children}</div>
  }
  const MyComponentTwo: React.FC = (children, { className }) => {
    // children is typed
    return <div className={className}>{children}</div>
  }
  const MyComponentThree = ({ children, className }: MyComponentWithChildredProps) => {
    // children is typed
    return <div className={className}>{children}</div>
  }
  /*
  const MyComponentFour = ({ className, children }: MyComponentProps) => {
    // children is NOT typed (var children: any)
    return <div className={className}>{children}</div>
  }
  */

  interface FormatDateProps {
    date: Date
  }

  function FormatDate({ date }: FormatDateProps): JSX.Element {
    return <div>{date.toLocaleString()}</div>
  }

  /*
    Mostly the content of the children prop is a JSX element, which can be typed using a special type JSX.Element
   */
  interface MessageProps {
    children: JSX.Element | JSX.Element[]
    important: boolean
  }

  function Message({ children, important }: MessageProps) {
    return (
      <div>
        {important ? 'Important message: ' : 'Regular message: '}
        {children}
      </div>
    )
  }

  return (
    <div>
      <Text variant="h1" style={{ padding: '0 0 4rem 0' }}>
        Typescript
      </Text>
      {/*<DisplayCar {...mustang}>My New Car</DisplayCar>*/}
      <TextareaAutosize
        maxRows={100}
        readOnly={true}
        aria-label="maximum height"
        placeholder="Maximum 4 rows"
        defaultValue="    
            interface containerProps {
              heading: string
              para: string
            }
          
            const container = (props: containerProps) => {
              return <></>
            }
          
            interface MyUserProps {
              first: string
              last: string
              middle?: string
            }
          
            const user1 = { first: 'string', last: 'string' }
            const user2: MyUserProps = { first: 'string', last: 'string' }
          
            // Simple yet effective
            // implicit return JSX.Element, ReactElement, etc
            const DisplayName1 = (props: MyUserProps) => {
              return (
                <div>
                  ${props.first}, ${props.last}
                </div>
              )
            }
          
            // More flexible, destructuring
            const DisplayName2 = ({ first, last }: MyUserProps) /*: React.ReactElement*/ => {
              return (
                <div>
                  ${first}, ${last}
                </div>
              )
            }
          
            //explicit function type declaration of FC
            const DisplayName3: React.FC<MyUserProps> = ({ first, last }) => {
              return (
                <div>
                  ${first}, ${last}
                </div>
              )
            }
            
            //explicit function type declaration of FC, children come for free!
            const DisplayName4: React.FC<MyUserProps> = (children, { first, last }) => {
              return (
                <>
                  <div>
                    ${first}, ${last}, {children}
                  </div>
                  <div>{children}</div>{' '}
                </>
              )
            }
          
            const DisplayName5: React.FC<MyUserProps> = (user: { first: string; last: string }) => {
              return (
                <div>
                  ${user.first}, ${user.last}
                </div>
              )
            }
                  "
        style={{ width: 600 }}
      />
    </div>
  )
}
