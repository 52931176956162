import React, { FC } from 'react'
import { Text } from 'components/Shared'

export const Faq: FC = () => {
  return (
    <>
      <Text variant="h1" style={{ padding: '0 0 4rem 0' }}>
        Frequently Asked Questions
      </Text>

      <Text variant="h4">Awesome website! What technologies does it use?</Text>
      <Text variant="body1" style={{ padding: '0 0 4rem 0' }}>
        This site is built with React, TypeScript, and MaterialUI, and retrieves data using AWS
        Serverless with Node.js Lambdas and the PAAPI.
      </Text>

      <Text variant="h4">Where do you get your information?</Text>
      <Text variant="body1" style={{ padding: '0 0 4rem 0' }}>
        All of our products are retrieved from the Amazon Marketplace as a part of the Amazon
        Affiliate Marketing Program.
      </Text>

      <Text variant="h4">What is the best laptop under $500?</Text>
      <Text variant="body1" style={{ padding: '0 0 4rem 0' }}>
        For budget (or any) laptops, one of the best things to consider is CPU speed and RAM. Having
        more than 8GB of RAM helps with running multiple programs at once, and having a fast CPU
        like the Core i3 - i9 series will be able to process those program requests quicker.
      </Text>

      <Text variant="h4">Which processor is the best?</Text>
      <Text variant="body1" style={{ padding: '0 0 4rem 0' }}>
        That depends on your budget, but a good starting place is the Intel Core I Series processors
        if you are a new laptop purchaser. Look at the GHz number to determine the speed of any
        processor, and pick one that aligns with your budget. AMD Ryzen and Athlon processors will
        have similar comparisions.
      </Text>

      <Text variant="h4">What is better for storage, HDD or SSD, or eMMC?</Text>
      <Text variant="body1" style={{ padding: '0 0 4rem 0' }}>
        The SSD drives are a good place to start because of their speed, capacity and price. eMMC
        can lower the overall price, but they will be slower, and HDD will be the slowest,
        relatively speaking.
      </Text>

      <Text variant="h4">How should I use your site to find the best laptop?</Text>
      <Text variant="body1" style={{ padding: '0 0 4rem 0' }}>
        Start with what matters the most to you. Is it money, speed, storage, manufacturer,
        operating system, size, display, etc.? We list the laptops from lowest to highest price and
        place the brand and model first so you can see how the technologies change with different
        brands as you get into the higher price levels. You can also filter each table column to
        group similar items so you can see the difference across the table. You can also use the
        header tabs above the table to shorten the list of laptops you are viewing if you&apos;re
        looking for something more specific.
      </Text>

      <Text variant="h4">Do you build ebusiness/ecommerce websites for clients?</Text>
      <Text variant="body1" style={{ padding: '0 0 4rem 0' }}>
        Yes. Please contact support@laptopshed.com with any consulting inquiries.
      </Text>

      <h3 style={{ color: '#F0F2F4' }}>
        laptop prices amazon laptops dell lenovo apple macbook hp react aws typescript serverless
        consulting
      </h3>
    </>
  )
}
