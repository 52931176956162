import { Palette } from '@mui/icons-material'
import { createTheme } from '@mui/material'
import { constants } from './constants'
import { robotoFontFaces, typography } from './typography'
const { colors } = constants

//console.log('robotoFontFaces: ', robotoFontFaces)
//console.log('typography: ', typography)

//use the theme to use your own colors and fonts
const baseTheme = createTheme({
  typography: {
    //fontFamily: ['Nunito', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    //fontFamily: 'Human BBY Digital',
    fontFamily: 'Roboto',
    h3: {
      fontSize: '1.6rem',
      lineHeight: '2.0rem',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '1.6rem',
      lineHeight: '2.0rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: '1.6rem',
      lineHeight: '2.0rem',
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#242526',
    },
    secondary: {
      main: '#d975d0',
    },
    text: {
      primary: '#E4E6EB',
      secondary: '#B0B3B8',
    },
    background: {
      default: '#242526',
      paper: '#242526',
    },
  },
})

const theme = createTheme({
  palette: {
    common: {
      white: colors.white,
      black: colors.black,
    },
    primary: {
      main: colors.primaryMain,
      dark: colors.primaryDark,
      light: colors.primaryLight,
    },
    secondary: {
      main: colors.secondaryMain,
      dark: colors.secondaryDark,
      light: colors.secondaryLight,
    },
    error: {
      main: colors.error,
      light: colors.errorLight,
    },
    success: {
      main: colors.success,
      light: colors.successLight,
    },
    warning: {
      main: colors.warn,
      light: colors.warnLight,
    },
    info: {
      main: colors.primaryLight,
      light: colors.primaryHighlight,
    },
    /*
    neutral: {
      main: colors.neutral,
      light: colors.neutralLight,
    },
    */
    grey: {
      ...colors.grey,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
    },
    background: {
      default: colors.grey.A100,
      paper: colors.white,
    },
  },
  typography,
  components: {
    //...overrides,
    // 'Material-UI: The @font-face: [font1, font2] API is no longer supported.'
    // 'Follow the upgrade guide on https://material-ui.com/r/migration-v4#cssbaseline.'
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {},
        '@font-face': robotoFontFaces[0],
        html: {
          fontSize: '62.5%',
        },
        //fallbacks: []
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '1rem',
          color: 'black',
        },
      },
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        disableRipple: true,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 960,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
})

const appTheme = createTheme(theme)
export { appTheme }
