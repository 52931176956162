import { ItemDto } from 'types/Item'

export const mock_special_items: ItemDto[] = [
  {
    asin: 'B00E0N19IK',
    detailPageURL: 'https://amzn.to/48wnoMD',
    itemInfo: {
      features: {
        displayValues: [
          'Traditional Medicinals Organic Nighty Night with Passionflower Herbal Tea',
          'Promotes a Good Night’s Sleep',
          '(Pack of 1) - 16 Tea Bags',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Nighty Night Tea',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            displayValue: 'nuevo',
            label: 'Condición',
            locale: 'es_US',
            value: 'New',
          },
          highestPrice: {
            amount: '4.49',
            currency: 'USD',
            displayAmount: '$4.49',
          },
        },
      ],
    },
    parentASIN: '-----------',
  },
  {
    asin: 'B09MJGMT4V',
    detailPageURL: 'https://amzn.to/3vqdY6J',
    itemInfo: {
      features: {
        displayValues: [
          'MUDWTR - 30 Serving :rise',
          'Mushroom-Based Coffee Alternative',
          'Low Caffeine & Organic Ingredients',
          'Vegan',
          'Gluten Free',
          'Non-GMO Whole30 Approved',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'MUD/WTR',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            displayValue: 'nuevo',
            label: 'Condición',
            locale: 'es_US',
            value: 'New',
          },
          highestPrice: {
            amount: '51.99',
            currency: 'USD',
            displayAmount: '$51.99',
          },
        },
      ],
    },
    parentASIN: '-----------',
  },
  {
    asin: 'B079GBLMQW',
    detailPageURL: 'https://amzn.to/48uAmdY',
    itemInfo: {
      features: {
        displayValues: [
          'Modvel Knee Braces for Knee Pain Women & Men',
          'Knee Brace Compression Sleeve',
          'Knee Support for Knee Pain Meniscus Tear',
          'ACL & Arthritis Pain Relief - Knee Sleeves',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Knee compression sleeves',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            displayValue: 'nuevo',
            label: 'Condición',
            locale: 'es_US',
            value: 'New',
          },
          highestPrice: {
            amount: '23.93',
            currency: 'USD',
            displayAmount: '$23.93',
          },
        },
      ],
    },
    parentASIN: '-----------',
  },
]
