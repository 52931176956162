import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react'
import { Paper, Tab, Tabs } from '@mui/material'
import useAllItems from 'hooks/items/useAllItemsCached'
import { Pill, Table, Text } from 'components/Shared'
import { getTabData } from './util'
import { colDefs } from 'constants/data/itemGridColumnDefs'
import { Item, ItemsTabData } from 'types'
import Footer from 'components/Footer'

//Modify the API data we received before we render it in the table
/*
const scrubItemData = (itemData: Item[] | undefined) => {
  return itemData
    ?.sort((x, y) => {
      return x.userId === accountEmail ? -1 : y.userId === accountEmail ? 1 : 0
    })
    .map((item) =>
      item.userId === accountEmail
        ? { ...item, editable: false, userId: item.userId + ' (You)' }
        : { ...item, editable: true }
    )
}
*/

const labelNode = (label: string, value: number) => (
  <>
    {label}
    <Pill label={value} size="small" />
  </>
)

export const ItemTable = (): JSX.Element => {
  //export default function Items(): ReactElement {
  const [Items, setItems] = useState<Item[]>([])
  const [filteredItems, setFilteredItems] = useState<Item[]>([])
  const [loading, setLoading] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [tabData, setTabData] = useState<ItemsTabData>({
    total: 0,
    new: 0,
    renewed: 0,
    apple: 0,
    windows: 0,
    price: 0,
  })

  const itemsResponse = useAllItems()
  useEffect(() => {
    const { data, isLoading, isError, isStale } = itemsResponse
    //console.log('ItemTable - isStale: ', isStale)
    //console.log('ItemTable - itemResponse hook - result: ', data)
    setItems(data || [])
    setFilteredItems(data || [])
    setLoading(isLoading)
    setTabData(getTabData(itemsResponse.data || []))
  }, [itemsResponse.data])

  const handleTabChange = (event: SyntheticEvent<Element, Event>, newValue: number) => {
    switch (newValue) {
      case 1:
        setFilteredItems(Items.filter((Item) => Item.condition === 'New'))
        break
      case 2:
        setFilteredItems(Items.filter((Item) => Item.condition === 'Renewed'))
        break
      case 3:
        setFilteredItems(Items.filter((Item) => Item.type === 'Mac OS'))
        break
      case 4:
        setFilteredItems(Items.filter((Item) => Item.type === 'Windows'))
        break
      case 5:
        setFilteredItems(Items.filter((Item) => Number(Item.price) < 500))
        break
      default:
        setFilteredItems(Items)
    }
    setTabValue(newValue)
  }

  return (
    <div>
      <Text variant="body1">
        Current Laptop listings from the Amazon Marketplace. Use the table to filter by column, or
        filter by groups using the tabs above the table.
      </Text>
      <Paper
        style={{ width: 'fit-content', overflow: 'hidden', margin: '3.2rem 0 1.6rem' }}
        elevation={0}
      >
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label={labelNode('All', tabData.total)} />
          <Tab label={labelNode('New', tabData.new)} />
          <Tab label={labelNode('Renewed', tabData.renewed)} />
          <Tab label={labelNode('Mac OS', tabData.apple)} />
          <Tab label={labelNode('Windows', tabData.windows)} />
          <Tab label={labelNode('Under $500', tabData.price)} />
        </Tabs>
      </Paper>
      <Table columnDefs={colDefs} rowData={filteredItems} paginated={true} />
      <Footer />
      <h1 style={{ color: '#F0F2F4' }}>
        amazon laptop prices amazon laptops dell lenovo apple macbook hp asus acer samsung microsoft
        msi intel celeron amd ryzen chromebook
      </h1>
    </div>
  )
}
