import { useEffect, useState } from 'react'
import { awsGatewayService } from 'services/AWSGatewayService'
import { AsyncHookResponse, Todo } from 'types'

const useAllTodos = (): AsyncHookResponse<Todo[]> => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [result, setResult] = useState<Todo[]>([])

  const fetchTodos = () => {
    setLoading(true)
    setError(false)

    awsGatewayService
      .getAllTodos()
      .then((todos) => {
        console.log('useAllTodos: ', todos)
        setResult(todos)
        setLoading(false)
      })
      .catch((e) => {
        console.error('Error:', e)
        setError(true)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchTodos()
  }, [])

  return { result, loading, error }
}

export default useAllTodos
