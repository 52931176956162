import { Button as MuiButton, ButtonProps, Stack } from '@mui/material'
import React, { ReactElement } from 'react'
import { CustomButton1 } from 'components/Shared/Button/CustomButton1'
import { CustomButton2 } from 'components/Shared/Button/CustomButton2'
import { CustomButton3 } from 'components/Shared/Button/CustomButton3'
import { CustomButton4 } from 'components/Shared/Button/CustomButton4'
import { Text } from 'components/Shared'

export default function Buttons(): ReactElement {
  interface ButtonTestProps {
    color: string
    value?: string
  }

  // explicit return type
  const ButtonTestOne: React.FC<ButtonTestProps> = ({ children, color }) => {
    const backColor = color === 'primary' ? 'orange' : 'yellow'
    return (
      <MuiButton variant="contained" style={{ backgroundColor: backColor }}>
        {children}
      </MuiButton>
    )
  }

  const ButtonTestTwo = (props: ButtonTestProps) => {
    return (
      <MuiButton
        variant="outlined"
        style={{
          backgroundColor: props.color,
        }}
      >
        {props.value}
      </MuiButton>
    )
  }

  const ButtonTestThree: React.FC<ButtonTestProps> = ({ children, color }) => {
    return (
      <MuiButton
        variant="contained"
        style={{
          backgroundColor: color,
        }}
      >
        {children}
      </MuiButton>
    )
  }

  interface MyButtonProps {
    border: string
    color: string
    textColor: string
    textSize: string
    children?: React.ReactNode
    height: string
    onClick: () => void
    radius: string
    width: string
  }

  //inferred prop type (MyButtonProps), and inferred return type (ReactElement)
  const MyButton: React.FC<MyButtonProps> = ({
    border,
    color,
    textColor,
    textSize,
    children,
    height,
    onClick,
    radius,
    width,
  }) => {
    return (
      <MuiButton
        onClick={onClick}
        style={{
          backgroundColor: color,
          color: textColor,
          fontSize: textSize,
          border,
          borderRadius: radius,
          height,
          width,
        }}
      >
        {children}
      </MuiButton>
    )
  }

  return (
    <div>
      <Text variant="h1" style={{ padding: '0 0 4rem 0' }}>
        Buttons
      </Text>

      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
        <MuiButton variant="contained" disabled>
          MUI Default Button
        </MuiButton>
        <CustomButton1 variant="outlined" value={'test'}>
          CustomButton1
        </CustomButton1>
        <CustomButton2 variant="contained">CustomButton2</CustomButton2>
        <CustomButton3>CustomButton3</CustomButton3>
        <CustomButton4
          {...{
            border: '1',
            color: 'blue',
            textColor: 'white',
            textSize: '14',
            height: '4rem',
            radius: '4',
            width: '12rem',
            disabled: false,
            onClick: () => {
              console.log('button was clicked')
            },
          }}
        >
          CustomButton4
        </CustomButton4>
      </Stack>
      <Stack
        style={{ padding: '4rem 0rem 0rem 0rem' }}
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <ButtonTestOne color="primary">Contained Button</ButtonTestOne>
        <ButtonTestTwo color="darkGrey" value="Outlined Button"></ButtonTestTwo>
        <ButtonTestThree color="pink">Text Button</ButtonTestThree>
      </Stack>
    </div>
  )
}
