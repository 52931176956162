import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { Color } from 'csstype'

interface PillStyleProps {
  color: Color
  backgroundColor: Color
  borderColor: Color
}

const usePillStyles = makeStyles<Theme, PillStyleProps>(() => ({
  chipOverride: (props) => ({
    ...props,
  }),
}))

export default usePillStyles
