import { Device, PowerStatus } from 'types'
import { AssignmentStatus } from 'types/enum'

export const mock_devices: Device[] = [
  {
    id: 12395678,
    powerStatus: PowerStatus.On,
    assignee: 'Mary',
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Assigned,
    activationDate: '1/20/20',
  },
  {
    id: 12115678,
    powerStatus: PowerStatus.Off,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.PendingReturn,
    activationDate: '2/20/20',
    flags: 'Broken',
  },
  {
    id: 12775678,
    powerStatus: PowerStatus.NotActivated,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Unassigned,
    activationDate: '3/20/20',
  },
  {
    id: 12885678,
    powerStatus: PowerStatus.Unknown,
    assignee: 'Mike',
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Assigned,
    activationDate: '4/20/20',
  },
  {
    id: 12915678,
    powerStatus: PowerStatus.On,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Unassigned,
    activationDate: '5/20/20',
  },
  // added new mocks
  {
    id: 12395678,
    powerStatus: PowerStatus.On,
    assignee: 'John',
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Assigned,
    activationDate: '1/20/20',
  },
  {
    id: 12115678,
    powerStatus: PowerStatus.Off,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.PendingReturn,
    activationDate: '2/20/20',
    flags: 'Broken',
  },
  {
    id: 12775678,
    powerStatus: PowerStatus.NotActivated,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Unassigned,
    activationDate: '3/20/20',
  },
  {
    id: 12885678,
    powerStatus: PowerStatus.Unknown,
    assignee: 'Judy',
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Assigned,
    activationDate: '4/20/20',
  },
  {
    id: 12915678,
    powerStatus: PowerStatus.On,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Unassigned,
    activationDate: '5/20/20',
  },
  {
    id: 12395678,
    powerStatus: PowerStatus.On,
    assignee: 'Jack',
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Assigned,
    activationDate: '1/20/20',
  },
  {
    id: 12115678,
    powerStatus: PowerStatus.Off,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.PendingReturn,
    activationDate: '2/20/20',
    flags: 'Broken',
  },
  {
    id: 12775678,
    powerStatus: PowerStatus.NotActivated,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Unassigned,
    activationDate: '3/20/20',
  },
  {
    id: 12885678,
    powerStatus: PowerStatus.Unknown,
    assignee: 'Jill',
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Assigned,
    activationDate: '4/20/20',
  },
  {
    id: 12915678,
    powerStatus: PowerStatus.On,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Unassigned,
    activationDate: '5/20/20',
  },
  {
    id: 12395678,
    powerStatus: PowerStatus.On,
    assignee: 'Liz',
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Assigned,
    activationDate: '1/20/20',
  },
  {
    id: 12115678,
    powerStatus: PowerStatus.Off,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.PendingReturn,
    activationDate: '2/20/20',
    flags: 'Broken',
  },
  {
    id: 12775678,
    powerStatus: PowerStatus.NotActivated,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Unassigned,
    activationDate: '3/20/20',
  },
  {
    id: 12885678,
    powerStatus: PowerStatus.Unknown,
    assignee: 'Levi',
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Assigned,
    activationDate: '4/20/20',
  },
  {
    id: 12915678,
    powerStatus: PowerStatus.On,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Unassigned,
    activationDate: '5/20/20',
  },
  {
    id: 12395678,
    powerStatus: PowerStatus.On,
    assignee: 'Drake',
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Assigned,
    activationDate: '1/20/20',
  },
  {
    id: 12115678,
    powerStatus: PowerStatus.Off,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.PendingReturn,
    activationDate: '2/20/20',
    flags: 'Broken',
  },
  {
    id: 12775678,
    powerStatus: PowerStatus.NotActivated,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Unassigned,
    activationDate: '3/20/20',
  },
  {
    id: 12885678,
    powerStatus: PowerStatus.Unknown,
    assignee: 'Darlene',
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Assigned,
    activationDate: '4/20/20',
  },
  {
    id: 12915678,
    powerStatus: PowerStatus.On,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Unassigned,
    activationDate: '5/20/20',
  },
  {
    id: 12395678,
    powerStatus: PowerStatus.On,
    assignee: 'Mary',
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Assigned,
    activationDate: '1/20/20',
  },
  {
    id: 12115678,
    powerStatus: PowerStatus.Off,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.PendingReturn,
    activationDate: '2/20/20',
    flags: 'Broken',
  },
  {
    id: 12775678,
    powerStatus: PowerStatus.NotActivated,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Unassigned,
    activationDate: '3/20/20',
  },
  {
    id: 12885678,
    powerStatus: PowerStatus.Unknown,
    assignee: 'Mike',
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Assigned,
    activationDate: '4/20/20',
  },
  {
    id: 12915678,
    powerStatus: PowerStatus.On,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Unassigned,
    activationDate: '5/20/20',
  },
  {
    id: 12395678,
    powerStatus: PowerStatus.On,
    assignee: 'Mary',
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Assigned,
    activationDate: '1/20/20',
  },
  {
    id: 12115678,
    powerStatus: PowerStatus.Off,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.PendingReturn,
    activationDate: '2/20/20',
    flags: 'Broken',
  },
  {
    id: 12775678,
    powerStatus: PowerStatus.NotActivated,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Unassigned,
    activationDate: '3/20/20',
  },
  {
    id: 12885678,
    powerStatus: PowerStatus.Unknown,
    assignee: 'Mike',
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Assigned,
    activationDate: '4/20/20',
  },
  {
    id: 12915678,
    powerStatus: PowerStatus.On,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Unassigned,
    activationDate: '5/20/20',
  },
  {
    id: 12395678,
    powerStatus: PowerStatus.On,
    assignee: 'Mary',
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Assigned,
    activationDate: '1/20/20',
  },
  {
    id: 12115678,
    powerStatus: PowerStatus.Off,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.PendingReturn,
    activationDate: '2/20/20',
    flags: 'Broken',
  },
  {
    id: 12775678,
    powerStatus: PowerStatus.NotActivated,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Unassigned,
    activationDate: '3/20/20',
  },
  {
    id: 12885678,
    powerStatus: PowerStatus.Unknown,
    assignee: 'Mike',
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Assigned,
    activationDate: '4/20/20',
  },
  {
    id: 12915678,
    powerStatus: PowerStatus.On,
    model: 'Lively Mobile',
    assignmentStatus: AssignmentStatus.Unassigned,
    activationDate: '5/20/20',
  },
]
