import React, { ReactElement, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Link } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
  Drawer,
  Toolbar,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@mui/material'
//import { Language as SiteIcon } from '@mui/material/Icon'
//import { Facility } from 'types'
//import useFacilityById from '../../hooks/facilities/useFacilityById'
import { StyledListItemButton } from './styles'
import {
  HomeIcon,
  InvoiceIcon,
  ListIcon,
  PeopleIcon,
  ContactIcon,
  FaqIcon,
  PrivacyIcon,
} from '../../assets/icons'
import { Text } from 'components/Shared'
import { constants } from '../../styles'

const navLinks = [
  //{ name: 'Overview', path: '/', icon: HomeIcon },
  //{ name: 'Theme', path: '/thememapping', icon: ListIcon },
  //{ name: 'Typography', path: '/typography', icon: ListIcon },
  //{ name: 'Colors', path: '/colors', icon: ListIcon },
  //{ name: 'Buttons', path: '/buttons', icon: PeopleIcon },
  //{ name: 'Cards', path: '/cards', icon: PeopleIcon },
  //{ name: 'Chat', path: '/chat', icon: InvoiceIcon },
  //{ name: 'Chat Image', path: '/chatimage', icon: InvoiceIcon },
  //{ name: 'Chat Models', path: '/chatmodels', icon: InvoiceIcon },
  //{ name: 'Chat Code', path: '/chatcode', icon: InvoiceIcon },
  { name: 'Laptops', path: '/', icon: ListIcon },
  { name: 'Drives', path: '/drives', icon: ListIcon },
  { name: 'Memory', path: '/memory', icon: ListIcon },
  { name: 'FAQ', path: '/faq', icon: FaqIcon },
  { name: 'Contact', path: '/contact', icon: ContactIcon },
  { name: 'Privacy', path: '/privacy', icon: PrivacyIcon },
  //{ name: 'Specials', path: '/specialprices', icon: ListIcon },
  //{ name: 'Target Prices', path: '/deviceprices', icon: ListIcon },
  //{ name: 'Todos', path: '/todos', icon: ListIcon },
  //{ name: 'Forms', path: '/forms', icon: InvoiceIcon },
  //{ name: 'Grids', path: '/grids', icon: InvoiceIcon },
  //{ name: 'Navigation', path: '/navigation', icon: InvoiceIcon },
  //{ name: 'Typescript', path: '/typescript', icon: InvoiceIcon },
]

export default function MainNav(): ReactElement {
  const [loading, setLoading] = useState(false)
  //  const [facility, setFacility] = useState<Facility>()

  /*
  const facilityResponse = useFacilityById(1000)
  useEffect(() => {
    setFacility(facilityResponse.result)
    setLoading(facilityResponse.loading)
  }, [facilityResponse.loading, facilityResponse.result])
  */

  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const setActive = (index: number) => {
    setSelectedIndex(index)
  }

  //Use for vertical side nav menu
  //https://javascript.works-hub.com/learn/how-to-create-a-responsive-navbar-using-material-ui-and-react-router-f9a01
  //const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const Facility = () => {
    if (!loading) {
      return (
        <>
          <Text variant="h3"></Text>
        </>
      )
    } else {
      return (
        <div>
          <CircularProgress />
        </div>
      )
    }
  }

  const theme = useTheme()
  //const classes = useNavStyles(theme) //useStyles(theme)

  return (
    <Drawer
      //className={classes.drawer}
      sx={{ width: constants.drawerWidth, paper: constants.drawerWidth, flexShrink: 0 }}
      variant="permanent"
      classes={
        {
          //paper: classes.drawerPaper,
        }
      }
    >
      <Toolbar />
      <nav
        //className={classes.drawerContainer}
        style={{ overflow: 'auto' }}
      >
        <Box textAlign="center" padding={0}>
          {/*<img width="160" src="images/shed.png" /> */}
        </Box>
        <List>
          {navLinks.map((item, key) => (
            <Link
              component={RouterLink}
              style={{ textDecoration: 'none' }}
              to={item.path}
              key={item.name}
            >
              <StyledListItemButton
                selected={key === selectedIndex}
                onClick={() => {
                  setActive(key)
                }}
              >
                <ListItemIcon
                  style={{
                    width: '24px',
                    height: '24px',
                  }}
                >
                  <item.icon />
                </ListItemIcon>
                <ListItemText primary={<Text style={{ fontWeight: 900 }}>{item.name}</Text>} />
              </StyledListItemButton>
            </Link>
          ))}
        </List>
        {/*
        <Box textAlign="left" maxWidth={'150px'} paddingLeft={'1rem'} paddingTop={'2rem'}>
          <Text variant="body1">
            This site provides a valuable resource to filter and search current laptop listings
            inconjunction with the Amazon Associates Program and products from Amazon.com
          </Text>
        </Box>
        */}
      </nav>
    </Drawer>
  )
}
