import React, { ReactElement } from 'react'
import { Chip, ChipProps, useTheme } from '@mui/material'
import usePillStyles from './styles'

export type PillColorOptions = 'primary' | 'secondary' | 'success' | 'warning' | 'error' | 'info'

export interface PillProps extends Pick<ChipProps, 'label' | 'variant' | 'style' | 'size'> {
  palette?: PillColorOptions
}

export default function Pill({ palette, label, ...props }: PillProps): ReactElement {
  const { palette: themePalette } = useTheme()
  const customColor = palette !== 'primary' && palette !== 'secondary'
  const parsedColors = palette && customColor && themePalette[palette]
  const classes =
    parsedColors &&
    customColor &&
    usePillStyles({
      color: parsedColors.main,
      backgroundColor: parsedColors.light,
      borderColor: props.variant === 'outlined' ? parsedColors.main : '',
    })
  const classList = classes && customColor ? { root: classes.chipOverride } : {}

  return (
    <Chip
      {...props}
      classes={classList}
      label={label}
      color={!customColor ? (palette as 'primary' | 'secondary') : undefined}
    />
  )
}
