import axios, { AxiosError, CancelToken } from 'axios'
import { mock_items } from 'constants/data/mockItems'
import { mock_disks } from 'constants/data/mockDisks'
import { mock_memory } from 'constants/data/mockMemory'
import { Disk, Item, Memory } from 'types'
import { delay } from 'utils/delay'
import { normalizeItem } from 'utils/normalizeItem'
import { normalizeDisk } from 'utils/normalizeDisk'
import { normalizeMemory } from 'utils/normalizeMemory'

const SERVERLESS_ITEMS_URL = process.env.REACT_APP_SERVERLESS_ITEMS_URL
const NODE_ENV = process.env.NODE_ENV
const USE_MOCK_DEVICES = process.env.REACT_APP_USE_MOCK_DEVICES === 'true'

const getAllItems = async (): Promise<Item[]> => {
  /*
  const { data } =
    USE_MOCK_DEVICES && NODE_ENV === 'development'
      ? await delay({ data: mock_items })
      : await axios.get<ItemDto[]>(`${SERVERLESS_ITEMS_URL}`).catch((error: AxiosError) => {
          if (error instanceof Error) {
            console.error('getAllItems error: ', error)
          }
          return {
            data: [],
          }
        })
  */
  const { data } = { data: mock_items }
  return data.map(normalizeItem)
}

const getAllDisks = async (): Promise<Disk[]> => {
  const { data } = { data: mock_disks }
  return data.map(normalizeDisk)
}

const getAllMemory = async (): Promise<Memory[]> => {
  const { data } = { data: mock_memory }
  return data.map(normalizeMemory)
}

export const itemService = {
  getAllItems,
  getAllDisks,
  getAllMemory,
}
