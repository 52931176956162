import { TypographyOptions } from '@mui/material/styles/createTypography'
import * as CSS from 'csstype'
import fonts from '../assets/fonts'
import { constants } from './constants'

/**
 * generates a fontFace rule from a fontUrl and fontName
 * @param fontUrl Url to font file
 * @param fontName Name of font family collection
 * @returns @fontFace CSS rule
 */
const fontFaceGenerator = (fontUrl: string, fontName: string): CSS.FontFace => {
  let fontWeight
  switch (true) {
    case fontUrl.toLocaleLowerCase().includes('light'):
      fontWeight = 300
      break
    case fontUrl.toLocaleLowerCase().includes('medium'):
      fontWeight = 500
      break
    case fontUrl.toLocaleLowerCase().includes('bold'):
      fontWeight = 700
      break
    case fontUrl.toLocaleLowerCase().includes('black'):
      fontWeight = 900
      break
    default:
      fontWeight = 400
      break
  }
  const fontStyle = fontUrl.toLocaleLowerCase().includes('italic') ? 'italic' : 'normal'

  return {
    fontFamily: fontName,
    fontStyle,
    fontWeight,
    fontDisplay: 'swap',
    src: `
    url(${fontUrl}) format('truetype')
    `,
  }
}

const humanBBYDigitalFontFaces = fonts.map((url) => {
  return fontFaceGenerator(url, 'Human BBY Digital')
})

const robotoFontFaces = fonts.map((url) => {
  return fontFaceGenerator(url, 'Roboto')
})

const typography: TypographyOptions = {
  // @mui/styles/createTypography.d.ts
  htmlFontSize: 10, // html.fontSize set to 62.5% in `CssBaseline` override
  fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(', '),
  ...constants.typography,
}
export { typography, robotoFontFaces }
