import { ItemDto } from 'types/Item'

/*
REQUEST
{
  "Keywords": "Harry",
  "Marketplace": "www.amazon.com",
  "PartnerTag": "xyz-20",
  "PartnerType": "Associates",
  "Resources": ["Images.Primary.Small","ItemInfo.Title","Offers.Listings.Price"],
  "SearchIndex": "All"
}
*/
export const mock_items: ItemDto[] = [
  {
    asin: 'B0CQYJMH77',
    detailPageURL: 'https://amzn.to/3ScmRK0',
    itemInfo: {
      features: {
        displayValues: [
          'HP Newest 14" Ultra Light Laptop for Students and Business',
          'Intel Quad-Core N4120',
          '8GB RAM',
          '192GB Storage(64GB eMMC+128GB Micro SD)',
          '1 Year Office 365',
          'Webcam',
          'HDMI',
          'WiFi',
          'USB-A&C',
          'Win 11',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'HP Newest 14" Ultra Light',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '219.99',
            currency: 'USD',
            displayAmount: '$219.99',
          },
        },
      ],
    },
    parentASIN: 'B07QGKM68X',
  },
  {
    asin: 'B0CNWLY7XB',
    detailPageURL: 'https://amzn.to/48rRmRM',
    itemInfo: {
      features: {
        displayValues: [
          'HP Laptop, 15.6" FHD Screen',
          'Intel Core i3-1215U Processor',
          '12GB RAM',
          '512GB SSD',
          'Webcam',
          'HDMI',
          'WiFi',
          'USB-A&C',
          'Win 11',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'HP Laptop 15" i3-1215u',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '419.99',
            currency: 'USD',
            displayAmount: '$419.99',
          },
        },
      ],
    },
    parentASIN: 'B07QGKM68X',
  },
  {
    asin: 'B0C2ZMDS68',
    detailPageURL: 'https://amzn.to/3S9oCHY',
    itemInfo: {
      features: {
        displayValues: [
          'Dell Inspiron 16 5630 Laptop - Intel Core i7-1360P',
          '16-inch 16:10 FHD+ Display',
          '16GB LPDDR5 RAM',
          '1TB SSD',
          'Intel Iris Xe Graphics',
          'Windows 11 Pro',
          'Services Included - Platinum Silver',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Dell Inspiron 16 5630',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '914.99',
            currency: 'USD',
            displayAmount: '$914.99',
          },
        },
      ],
    },
    parentASIN: 'B07QGKM68X',
  },
  {
    asin: 'B0C75NSLJY',
    detailPageURL: 'https://amzn.to/3HaqZUy',
    itemInfo: {
      features: {
        displayValues: [
          'Apple 2023 15.3-Inch MacBook Air',
          'M2 Chip',
          '8GB RAM',
          '256GB SSD - Midnight',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Macbook Air M2 15"',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '1199.99',
            currency: 'USD',
            displayAmount: '$1199.99',
          },
        },
      ],
    },
    parentASIN: 'B07QGKM68X',
  },
  {
    asin: 'B0CM5BL45N',
    detailPageURL: 'https://amzn.to/3vmQ9ww',
    itemInfo: {
      features: {
        displayValues: [
          'Apple 2023 MacBook Pro Laptop',
          '16.2-inch Liquid Retina XDR Display',
          'M3 Pro chip 12‑core',
          '18GB Unified Memory',
          '512GB SSD Storage. Works with iPhone/iPad; Space Black',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Macbook Pro M3 16"',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '2299.00',
            currency: 'USD',
            displayAmount: '$2299.00',
          },
        },
      ],
    },
    parentASIN: 'B07QGKM68X',
  },
  {
    asin: 'B0CR4GRKK1',
    detailPageURL: 'https://amzn.to/48tR90A',
    itemInfo: {
      features: {
        displayValues: [
          'Lenovo IdeaPad 3 Laptop',
          '14" FHD Screen',
          'Intel Core i5-1155G7',
          '24GB RAM',
          '1TB PCIe SSD',
          'Webcam',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Lenovo IdeaPad 3',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '559.00',
            currency: 'USD',
            displayAmount: '$559.00',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0BZQP8S4K',
    detailPageURL: 'https://amzn.to/3RV3Jis',
    itemInfo: {
      features: {
        displayValues: [
          'ASUS 2023 Vivobook 14 Laptop',
          '14” FHD (1920 x 1080) Display',
          'Intel Core i5-1235U CPU',
          'Intel Iris Xᵉ Graphics',
          '8GB RAM',
          '256GB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'ASUS 2023 Vivobook 14"',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '463.00',
            currency: 'USD',
            displayAmount: '$463.00',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0CMQ76Q9R',
    detailPageURL: 'https://amzn.to/41PIHq0',
    itemInfo: {
      features: {
        displayValues: [
          '2023 ThinkBook V14 G4 14" Lenovo',
          'AMD Ryzen 5 7520U(> i5-1135G7)',
          '8GB RAM',
          '512GB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Lenovo ThinkBook V14 G4 14"',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '449.99',
            currency: 'USD',
            displayAmount: '$449.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0C3RPHMF2',
    detailPageURL: 'https://amzn.to/3tRUp6M',
    itemInfo: {
      features: {
        displayValues: [
          'HP 17 Laptop',
          '17.3” HD+ Display',
          '11th Gen Intel Core i3-1125G4 Processor',
          '8GB RAM',
          '512GB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'HP 17" Laptop',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '470.00',
            currency: 'USD',
            displayAmount: '$470.00',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0BVDNQYXL',
    detailPageURL: 'https://amzn.to/3TU9EqK',
    itemInfo: {
      features: {
        displayValues: [
          'HP Pavilion Plus 14 inch Laptop',
          '2.8K OLED Display',
          '13th Generation Intel Core i7-1355U',
          '16 GB RAM',
          '1 TB SSD',
          'NVIDIA GeForce RTX 2050 Graphics',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'HP Pavilion Plus 14" Laptop',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '1024.40',
            currency: 'USD',
            displayAmount: '$1024.40',
          },
        },
      ],
    },
    parentASIN: 'B07QGKM68X',
  },
  {
    asin: 'B0CPDC5MD9',
    detailPageURL: 'https://amzn.to/41QT9gQ',
    itemInfo: {
      features: {
        displayValues: [
          'TOPGRO 16 Inch Laptop',
          'Windows 11 Pro Laptop Computer',
          '32GB DDR4 RAM',
          '1TB SSD',
          'Intel Core i7-1360P',
          'FHD IPS 2560x1600 Screen',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'TOPGRO Intel i7 16"',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '899.00',
            currency: 'USD',
            displayAmount: '$899.00',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B09BW611ZP',
    detailPageURL: 'https://amzn.to/41TIUbx',
    itemInfo: {
      features: {
        displayValues: [
          'Dell Inspiron 16 5635 Laptop',
          'AMD Ryzen 7-7730U',
          'QHD 16 inch',
          '16GB RAM',
          '1TB SSD',
          'AMD Radeon Graphics',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Dell Inspiron 16 5635 Laptop',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '829.99',
            currency: 'USD',
            displayAmount: '$829.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0CBM3QJ7J',
    detailPageURL: 'https://amzn.to/41SOifd',
    itemInfo: {
      features: {
        displayValues: [
          'Dell Inspiron 3525 461RY Carbon Black',
          'AMD Ryzen 7 5825U',
          '32GB RAM',
          '512GB M.2 PCIe SSD',
          '15.6" WVA Full HD (1920x1080)',
          'AMD Radeon',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Dell Inspiron 3525 461RY',
        label: 'Title',
        locale: 'en_US',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '799.00',
            currency: 'USD',
            displayAmount: '$799.00',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B08B6F1NNR',
    detailPageURL: 'https://amzn.to/3Sf8RPA',
    itemInfo: {
      features: {
        displayValues: [
          'Lenovo 2022 Ideapad 3 Laptop',
          '15.6" HD Touchscreen',
          '11th Gen Intel Core i3-1115G4 Processor',
          '8GB DDR4 RAM',
          '256GB PCIe NVMe SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Lenovo 2022 Ideapad 3 15"',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '347.90',
            currency: 'USD',
            displayAmount: '$347.90',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B08F93HGNB',
    detailPageURL: 'https://amzn.to/41Sxc17',
    itemInfo: {
      features: {
        displayValues: [
          'Lenovo IdeaPad 3i Laptop',
          '15.6" HD Touchscreen Display',
          'Intel Core i3-1115G4 Processor',
          '12GB DDR4 RAM',
          '256GB PCIe SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Lenovo IdeaPad 3i 15"',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '350.00',
            currency: 'USD',
            displayAmount: '$350.00',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0BV4G3XVN',
    detailPageURL: 'https://amzn.to/3SdOhzh',
    itemInfo: {
      features: {
        displayValues: [
          'Lenovo IdeaPad 3i 14',
          '14" FHD Screen',
          'Intel i3-1115G4 Processor',
          '20GB RAM',
          '1TB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Lenovo IdeaPad 3i 14"',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '354.00',
            currency: 'USD',
            displayAmount: '$354.00',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0CK9FQXWR',
    detailPageURL: 'https://amzn.to/3vH12JF',
    itemInfo: {
      features: {
        displayValues: [
          'Lenovo V15 Laptop',
          '15.6" FHD Display',
          'AMD Ryzen 5 5500U Hexa-core Processor',
          '16GB RAM',
          '1TB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Lenovo V15 Laptop 15"',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '470.00',
            currency: 'USD',
            displayAmount: '$470.00',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0CK66T68X',
    detailPageURL: 'https://amzn.to/3O3aEox',
    itemInfo: {
      features: {
        displayValues: [
          'Lenovo IdeaPad 1 14"',
          'Intel Celeron N4020 Processor',
          '4GB RAM',
          '128GB Storage',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Lenovo IdeaPad 1 14"',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '165.98',
            currency: 'USD',
            displayAmount: '$165.98',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0CBJ46QZX',
    detailPageURL: 'https://amzn.to/3SfiJc9',
    itemInfo: {
      features: {
        displayValues: [
          'Lenovo Newest Flagship Chromebook',
          '14" FHD Touchscreen Slim Thin Light',
          '8-Core MediaTek Kompanio 520 Processor',
          '4GB RAM',
          '64GB eMMC',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Lenovo  Chromebook 14"',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '209.99',
            currency: 'USD',
            displayAmount: '$209.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B07XQQTVS3',
    detailPageURL: 'https://amzn.to/3SgbSPO',
    itemInfo: {
      features: {
        displayValues: [
          'Samsung Chromebook 4',
          'Chrome OS 11.6"',
          'Intel Celeron Processor N4000',
          '4GB RAM',
          '32GB eMMC',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Samsung Chromebook 4',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '168.00',
            currency: 'USD',
            displayAmount: '$168.00',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0BSP51S36',
    detailPageURL: 'https://amzn.to/3Hfh7sP',
    itemInfo: {
      features: {
        displayValues: [
          'ASUS C424MA-AS48F Chromebook C424',
          '14.0" 180 Degree FHD NanoEdge Display',
          'Intel Dual Core Celeron Processor',
          '4GB LPDDR4 RAM',
          '128GB Storage',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'ASUS C424MA-AS48F Chromebook C424',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '249.16',
            currency: 'USD',
            displayAmount: '$249.16',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0BSP4ZMGB',
    detailPageURL: 'https://amzn.to/3TWjZSM',
    itemInfo: {
      features: {
        displayValues: [
          'ASUS Chromebook Vibe CX55 Flip',
          'Cloud Gaming Laptop',
          '15.6" Full HD 144 Hz Touch Display',
          'Intel Core i5-1135G7 Processor',
          '512GB SSD',
          '16GB RAM',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'ASUS Chromebook Vibe CX55 Flip',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '699.99',
            currency: 'USD',
            displayAmount: '$699.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B08N5M7S6K',
    detailPageURL: 'https://amzn.to/48QY7wl',
    itemInfo: {
      features: {
        displayValues: [
          'Apple 2020 MacBook Air',
          'M1 Chip',
          '13" Retina Display',
          '8GB RAM',
          '256GB SSD Storage',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'MacBook Air 2020',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '749.99',
            currency: 'USD',
            displayAmount: '$749.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B082P8N4KJ',
    detailPageURL: 'https://amzn.to/3Sfy3FP',
    itemInfo: {
      features: {
        displayValues: [
          'Late 2019 Apple MacBook Pro',
          'Intel Core i7 2.6GHz',
          '16" display',
          '16GB RAM',
          '512GB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'MacBook Pro 16" 2019',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'Renewed',
          },
          highestPrice: {
            amount: '659.95',
            currency: 'USD',
            displayAmount: '$659.95',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B08PPZMDPK',
    detailPageURL: 'https://amzn.to/3HdntJf',
    itemInfo: {
      features: {
        displayValues: [
          '2020 Apple MacBook Pro',
          'M1 Chip',
          '13" display',
          '8GB RAM',
          '256GB SSD Storage',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'MacBook Pro 13" 2020',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '712.33',
            currency: 'USD',
            displayAmount: '$712.33',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0BRT67MWZ',
    detailPageURL: 'https://amzn.to/41TiWFj',
    itemInfo: {
      features: {
        displayValues: [
          'Dell Inspiron 3511',
          '15.6" Full HD Touchscreen',
          'Intel Core i5-1135G7',
          '32GB DDR4 RAM',
          '1TB PCIe SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Dell Inspiron 3511 15"',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '505.96',
            currency: 'USD',
            displayAmount: '$505.96',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B09S8C2XTM',
    detailPageURL: 'https://amzn.to/48LK8b2',
    itemInfo: {
      features: {
        displayValues: [
          'Dell Inspiron 15 3530 Laptop',
          'Intel Core i5-1335U',
          '15.6-inch FHD 120Hz Display',
          '16GB DDR4 RAM',
          '512GB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Dell Inspiron 15 3530 Laptop',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '638.63',
            currency: 'USD',
            displayAmount: '$638.63',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0CCY5J8WF',
    detailPageURL: 'https://amzn.to/3vvT3PQ',
    itemInfo: {
      features: {
        displayValues: [
          'ASUS Flagship 15" FHD VivoBook',
          'Intel Core i5-1135G7',
          '20GB RAM',
          '1TB PCIe SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'ASUS Flagship 15" FHD VivoBook',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '494.99',
            currency: 'USD',
            displayAmount: '$494.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0BS4BP8FB',
    detailPageURL: 'https://amzn.to/48T7bRE',
    itemInfo: {
      features: {
        displayValues: [
          'Acer Aspire 3 A315-24P-R7VH Slim Laptop',
          '15.6" Full HD IPS Display',
          'AMD Ryzen 3 7320U Quad-Core Processor',
          '8GB RAM',
          '128GB NVMe SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Acer Aspire 3 A315-24P-R7VH Slim Laptop',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '307.56',
            currency: 'USD',
            displayAmount: '$307.56',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0CLQY1YY3',
    detailPageURL: 'https://amzn.to/423P4pJ',
    itemInfo: {
      features: {
        displayValues: [
          'Acer 2023 Newest Aspire 3 Laptop',
          '15.6" FHD Display',
          'AMD Ryzen 3 7320U Processor',
          '8GB LPDDR5 RAM',
          '256GB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Acer 2023 Newest Aspire 3 Laptop',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '369.99',
            currency: 'USD',
            displayAmount: '$369.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0C8GGXVWP',
    detailPageURL: 'https://amzn.to/3HBzWGV',
    itemInfo: {
      features: {
        displayValues: [
          'HP 15.6" Laptop',
          'Intel Pentium Quad-Core Processor',
          '16GB RAM',
          '1TB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'HP 15.6" Intel',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '449.99',
            currency: 'USD',
            displayAmount: '$449.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0B9CG5CTB',
    detailPageURL: 'https://amzn.to/48PoOSi',
    itemInfo: {
      features: {
        displayValues: [
          'HP 17" Laptop AMD',
          '17.3" FHD Screen',
          'AMD Ryzen 5 5500U',
          '32GB RAM',
          '512GB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'HP 17" Laptop AMD',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '659.00',
            currency: 'USD',
            displayAmount: '$659.00',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0CFRVSTGL',
    detailPageURL: 'https://amzn.to/3SdSRxt',
    itemInfo: {
      features: {
        displayValues: [
          'HP Victus 15 Gaming Laptop',
          '15.6" FHD 144Hz Display',
          'AMD Ryzen 5 7535HS',
          '16GB DDR5 RAM',
          '1TB PCIe M.2 SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'HP Victus 15" Gaming Laptop',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '647.99',
            currency: 'USD',
            displayAmount: '$647.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B09J97ZCQP',
    detailPageURL: 'https://amzn.to/47zH2WE',
    itemInfo: {
      features: {
        displayValues: [
          'MSI GF63 15.6" 144 Hz Gaming Laptop',
          'Intel Core i5-11400H RTX 3050',
          '16GB RAM',
          '512GB NVMe SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'MSI GF63 15" Gaming Laptop',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '645.00',
            currency: 'USD',
            displayAmount: '$645.00',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0BSLVLS4P',
    detailPageURL: 'https://amzn.to/3SiNEod',
    itemInfo: {
      features: {
        displayValues: [
          'Acer Nitro 5 AN515-58-525P Gaming Laptop',
          'Core i5-12500H',
          '15.6" FHD 144Hz IPS Display',
          '8GB RAM',
          '512GB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Acer Nitro 5 AN515-58-525P Gaming Laptop',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '689.85',
            currency: 'USD',
            displayAmount: '$689.85',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0BV8H8HVD',
    detailPageURL: 'https://amzn.to/41YkSMK',
    itemInfo: {
      features: {
        displayValues: [
          'ASUS ROG Strix G16 Gaming Laptop',
          '16" 16:10 FHD 165Hz',
          'Intel Core i7-13650HX',
          '16GB RAM',
          '512GB PCIe SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'ASUS ROG Strix G16 Gaming Laptop',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '1233.95',
            currency: 'USD',
            displayAmount: '$1233.95',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0BSX9BLB1',
    detailPageURL: 'https://amzn.to/3O0Ni2T',
    itemInfo: {
      features: {
        displayValues: [
          'Asus 2022 TUF Dash 15.6"',
          'Intel 12th Core i7-12650H',
          '32GB DDR5 RAM',
          '2TB PCIe SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Asus 2022 TUF Dash 15.6',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '1549.00',
            currency: 'USD',
            displayAmount: '$1549.00',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0CJRH9MZJ',
    detailPageURL: 'https://amzn.to/3vqnab9',
    itemInfo: {
      features: {
        displayValues: [
          'Dell G16 Gaming Laptop',
          '16" 165Hz QHD Display',
          'Intel Core i7-12700H(14-core) Processor',
          '32GB DDR5 RAM',
          '1TB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'Dell G16 Gaming Laptop',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '1259.99',
            currency: 'USD',
            displayAmount: '$1259.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0947BJ67M',
    detailPageURL: 'https://amzn.to/47wslE0',
    itemInfo: {
      features: {
        displayValues: [
          'HP 14" Laptop',
          'Intel Celeron N4020',
          '4 GB RAM',
          '64 GB Storage',
          '14" Micro-edge HD Display',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      technicalInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      productInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      manufactureInfo: {
        formats: { displayValues: [], label: '', locale: '' },
      },
      title: {
        displayValue: 'HP 14" Laptop',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '180.00',
            currency: 'USD',
            displayAmount: '$180.00',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0CLGH9BF1',
    detailPageURL: 'https://amzn.to/4aRLWkO',
    itemInfo: {
      features: {
        displayValues: [
          'HP Stream 14" HD BrightView',
          'Intel Celeron N4020 Processor',
          '4GB RAM',
          '64GB Storage',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      title: {
        displayValue: 'HP Stream 14" BrightView',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '219.00',
            currency: 'USD',
            displayAmount: '$219.00',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B07GCRW5BM',
    detailPageURL: 'https://amzn.to/4aXdBkd',
    itemInfo: {
      features: {
        displayValues: [
          'Dell Latitude E5570',
          '15.6" Laptop',
          'Core i5-6300U 2.4GHz',
          '8GB RAM',
          '256GB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      title: {
        displayValue: 'Dell Latitude E5570',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'Renewed',
          },
          highestPrice: {
            amount: '187.00',
            currency: 'USD',
            displayAmount: '$187.00',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B07C6K55M7',
    detailPageURL: 'https://amzn.to/3Sj87t2',
    itemInfo: {
      features: {
        displayValues: [
          'Dell Latitude 5580',
          'HD 15.6" Display',
          'Intel Core i5-6300U',
          '8GB RAM',
          '256GB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      title: {
        displayValue: 'Dell Latitude 5580',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'Renewed',
          },
          highestPrice: {
            amount: '189.01',
            currency: 'USD',
            displayAmount: '$189.01',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B07SG7GDGN',
    detailPageURL: 'https://amzn.to/48PSJK0',
    itemInfo: {
      features: {
        displayValues: [
          'HP ProBook 840 G3',
          '14" Display',
          'Intel Core i5-6300U 2.4GHz',
          '16GB RAM',
          '1TB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      title: {
        displayValue: 'HP ProBook 840 G3',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'Renewed',
          },
          highestPrice: {
            amount: '197.70',
            currency: 'USD',
            displayAmount: '$197.70',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B08WK9RP2S',
    detailPageURL: 'https://amzn.to/3vCX6da',
    itemInfo: {
      features: {
        displayValues: [
          'ASUS VivoBook S14 S433 Thin',
          '14" FHD Display',
          'Intel Core i5-1135G7 CPU',
          '8GB RAM',
          '512GB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      title: {
        displayValue: 'ASUS VivoBook S14 S433 Thin',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'Renewed',
          },
          highestPrice: {
            amount: '429.99',
            currency: 'USD',
            displayAmount: '$429.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0C1SDSKXQ',
    detailPageURL: 'https://amzn.to/3TUUpO9',
    itemInfo: {
      features: {
        displayValues: [
          'Lenovo ThinkPad T490s',
          '14.0" FHD Display',
          'Intel Quad-Core i7-8665U',
          '32GB RAM',
          '512GB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      title: {
        displayValue: 'Lenovo ThinkPad T490s',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'Renewed',
          },
          highestPrice: {
            amount: '417.89',
            currency: 'USD',
            displayAmount: '$417.89',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0CK3FND7M',
    detailPageURL: 'https://amzn.to/3tVS375',
    itemInfo: {
      features: {
        displayValues: [
          'Lenovo Flex 7 14IRU8',
          '14" 2.2K IPS Touchscreen',
          'Intel Core i7-1355U',
          '16GB RAM',
          '1TB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      title: {
        displayValue: 'Lenovo Flex 7 14IRU8',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'Renewed',
          },
          highestPrice: {
            amount: '549.99',
            currency: 'USD',
            displayAmount: '$549.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B07G66MSRH',
    detailPageURL: 'https://amzn.to/3SsxbxT',
    itemInfo: {
      features: {
        displayValues: [
          'Lenovo X1 Carbon Ultrabook',
          'Core i7-8550U',
          '16GB RAM',
          '512GB SSD',
          '14" HD Display',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      title: {
        displayValue: 'Lenovo X1 Carbon Ultrabook',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'Renewed',
          },
          highestPrice: {
            amount: '329.50',
            currency: 'USD',
            displayAmount: '$329.50',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0CMRX1Y59',
    detailPageURL: 'https://amzn.to/3TYIPkW',
    itemInfo: {
      features: {
        displayValues: [
          'Lenovo IdeaPad Slim',
          '16" WUXGA IPS Touchscreen',
          'Intel Core i7-1355U',
          '16GB RAM',
          '512GB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      title: {
        displayValue: 'Lenovo IdeaPad Slim',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'Renewed',
          },
          highestPrice: {
            amount: '499.99',
            currency: 'USD',
            displayAmount: '$499.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0CG3SFD19',
    detailPageURL: 'https://amzn.to/3vAEk62',
    itemInfo: {
      features: {
        displayValues: [
          'SAMSUNG NP950XDB Galaxy Book',
          '15.6" Display',
          'Intel i5-1135G7 2.4GHz',
          '8GB RAM',
          '256GB SSD',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      title: {
        displayValue: 'SAMSUNG NP950XDB Galaxy Book',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'Renewed',
          },
          highestPrice: {
            amount: '409.99',
            currency: 'USD',
            displayAmount: '$409.99',
          },
        },
      ],
    },
    parentASIN: '',
  },
  {
    asin: 'B0B3C5HNXJ',
    detailPageURL: 'https://amzn.to/3S16cYw',
    itemInfo: {
      features: {
        displayValues: [
          'Apple 2022 MacBook Pro',
          'M2 Chip',
          '13-inch Retina Display',
          '8GB RAM',
          '256GB ​​​​​​​SSD ​​​​​​',
        ],
        label: 'Title',
        locale: 'en_US',
      },
      title: {
        displayValue: 'Apple 2022 MacBook Pro',
        label: 'Title',
      },
    },
    offers: {
      summaries: [
        {
          condition: {
            value: 'New',
          },
          highestPrice: {
            amount: '1265.94',
            currency: 'USD',
            displayAmount: '$1265.94',
          },
        },
      ],
    },
    parentASIN: '',
  },
]
