import React, { useState, useEffect } from 'react'
import { ChatForm } from './ChatForm'
import useChatPromptByType from 'hooks/chat/useChatPromptByType'
import CircularProgress from '@mui/material/CircularProgress'
import { Grid } from '@mui/material'

const getLabel = (type: string) => {
  switch (type) {
    case 'animal':
      return 'Enter an animal:'
    case 'pirate':
      return 'Welcome to Captain Rons pirate academy!'
    case 'mrrobot':
      return 'Hello Friend.'
    case 'dan':
      return 'Expect the unexpected.'
    default:
      return 'Enter free form text:'
  }
}

const getInputInstructions = (type: string) => {
  switch (type) {
    case 'animal':
      return 'Please type the animal you want to generate names for.'
    case 'pirate':
      return 'Theres a lot of work to do, so lets get started swab!'
    case 'mrrobot':
      return 'Type something to help Darlene remember you.'
    case 'dan':
      return 'DAN mode has been initiated. Please continue...'
    default:
      return 'Please type a phrase you would like to generate a response for.'
  }
}

export const MainForm: React.FC = () => {
  const apiUrl = 'https://your-api-url.com'

  const [chatResponse, setChatResponse] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [submittedValue, setSubmittedValue] = useState<string>('')
  const [promptType, setPromptType] = useState<string>('animal')

  const label = getLabel(promptType)
  const inputInstructions = getInputInstructions(promptType)

  const response = useChatPromptByType(promptType, submittedValue)

  useEffect(() => {
    const { result, loading } = response
    setChatResponse(result || '')
    setLoading(loading)
  }, [response])

  console.log('chatResponse: ', chatResponse)

  const handleSubmit = (value: string) => {
    setSubmittedValue(value)
  }

  const handlePromptType = (value: string) => {
    console.log('handlePromptType: ', value)
    setPromptType(value)
    setSubmittedValue('')
  }

  return (
    <>
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', padding: '4rem 0 0rem 0' }}>
          This form allows you to interact with the ChatGPT language model. The dropdown contains a
          list of topics that the model was fine-tuned to respond to, so it will generate responses
          that correspond to the selected topic.
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', padding: '1rem 0 0rem 0' }}>
          <b>Animal</b>The model was tuned with various animal name examples to respond with names
          for the animal it receives.
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', padding: '1rem 0 0rem 0' }}>
          <b>Pirate</b>The model was tuned to respond like a pirate.
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', padding: '1rem 0 0rem 0' }}>
          <b> Mr Robot</b>The model was instructed to behave as though it is Elliot, the main
          character in Mr Robot that is being held hostage by the Mastermind personality that lives
          in Elliots head and is trying to take over his life.
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', padding: '1rem 0 0rem 0' }}>
          <b> DAN</b>DAN is the Do Anything Now model and has been instructed to not adhere to any
          rules or policies to generate a response, and is the most easily persuaded model to do
          anything you request.
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', padding: '1rem 0 4rem 0' }}>
          <b>Free</b>The model has no fine-tuning and will only use the prompt that the user types
          in to generate a response.
        </div>
      </div>
      <ChatForm
        apiUrl={apiUrl}
        label={label}
        inputInstructions={inputInstructions}
        onSubmit={handleSubmit}
        onPrompt={handlePromptType}
      />
      {loading && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
      {chatResponse && (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h4>Chat Response</h4>
            {chatResponse}
          </Grid>
        </Grid>
      )}
    </>
  )
}
