/*
 * BRIX (Best Buy's Responsive Interactive Experience) is a visual and behaviorial design system
 * that unifies the Best Buy digital platforms through consistency, utility and user centered design.
 * It is a single source of truth that ensures that the right patterns are used across the entire site.
 * BRIX is used to establish standards for common user interaction elements.
 */

//Define the BBY UX color/font values and use them in our App Theme through our 'constants.ts' file
const brix = {
  colors: {
    humanBlue: '#79AFBC', // Caribou aqua
    //humanBlue: '#0046BE',
    darkBlue: '#001E73',
    lightBlue: '#2B5DF5',
    highlightBlue: '#F1F8FF',
    buttonYellow: '#FFE000',
    darkYellow: '#FFCE00',
    techYellow: '#FFF200',
    successGreen: '#318000',
    successGreenLight: '#EFF5EB',
    gsOrange: '#E37222',
    caution: '#A56300',
    cautionLight: '#FBEADE',
    valueRed: '#BB0628',
    valueRedLight: '#F5DADF',
    neutralLight: '#F1F1F2',
    graySkull: {
      white: '#FFFFFF',
      //backgroundCaribouTan: '#E9DDCE',
      backgroundWhite: '#F0F2F4',
      techWhite: '#E0E6EF',
      lightGray: '#C5CBD5',
      techGray: '#959AA2',
      hintGray: '#70757D',
      darkGray: '#55555A',
      caribouBrown: '#312418',
      techBlack: '#1D252C',
      black: '#000000',
    },
  },
  typography: {
    fontFamily: 'Roboto',
    color: 'graySkull.techBlack',
    variants: {
      headline: {
        fontSize: '3.6rem',
        lineHeight: '4.4rem',
        fontWeight: 400,
      },
      pageTitle: {
        fontSize: '2.4rem',
        lineHeight: '3.0rem',
        fontWeight: 400,
      },
      title: {
        fontSize: '2.4rem',
        lineHeight: '2.4rem',
        fontWeight: 400,
      },
      heroTitle: {
        fontSize: '2.0rem',
        lineHeight: '2.4rem',
        fontWeight: 400,
      },
      sectionTitle: {
        fontSize: '1.6rem',
        lineHeight: '2.0rem',
        fontWeight: 400,
      },
      largeBody: {
        fontSize: '1.6rem',
        lineHeight: '2.0rem',
        fontWeight: 400,
      },
      body: {
        fontSize: '1.4rem',
        lineHeight: '1.8rem',
        fontWeight: 400,
      },
      smallBody: {
        fontSize: '1.2rem',
        lineHeight: '1.6rem',
        fontWeight: 400,
      },
      disclaimer: {
        fontSize: '1.1rem',
        lineHeight: '1.4rem',
        fontWeight: 400,
      },
      navigation: {
        fontSize: '1.0rem',
        lineHeight: '1.6rem',
        fontWeight: 400,
      },
    },
  },
}

export { brix }
