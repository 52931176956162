import React, { FC } from 'react'
import { Text } from 'components/Shared'

export const Contact: FC = () => {
  return (
    <>
      <Text variant="h1" style={{ padding: '0 0 4rem 0' }}>
        Contact Us
      </Text>

      <Text variant="body1" style={{ padding: '0 0 4rem 0' }}>
        Please email support@laptopshed.com with any questions or comments.
      </Text>
    </>
  )
}
