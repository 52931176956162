import React, { FC, MouseEventHandler } from 'react'
import { Button as MuiButton, ButtonProps, styled } from '@mui/material'
import { constants } from 'styles'
import { Link as RouterLink } from 'react-router-dom'

export interface AppProps {
  buttonName: string
  clickAction?: string
  to?: string
}

export const CustomButton2: FC<Partial<AppProps> & ButtonProps> = ({
  buttonName,
  clickAction,
  onClick,
  to,
  children,
  classes,
  size,
  variant,
  startIcon,
  disabled,
  style,
  color,
}) => {
  const handleClick: MouseEventHandler = (e) => {
    onClick && onClick(e as React.MouseEvent<HTMLButtonElement, MouseEvent>)
  }

  return to ? (
    <MuiButton
      variant={variant}
      disabled={disabled}
      classes={classes}
      startIcon={startIcon}
      size={size}
      onClick={handleClick}
      component={RouterLink}
      to={to}
      style={style}
      color={color}
    >
      {children}
    </MuiButton>
  ) : (
    <MuiButton
      variant={variant}
      disabled={disabled}
      classes={classes}
      startIcon={startIcon}
      size={size}
      onClick={handleClick}
      style={style}
      color={color}
    >
      {children}
    </MuiButton>
  )
}
