import React, { FC } from 'react'
import { Button as MuiButton, styled } from '@mui/material'
import { constants } from 'styles'

const MyThemeButton = styled(MuiButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.success.main,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  variant: 'outlined',
  '&:hover': {
    color: theme.palette.success.main,
    backgroundColor: constants.colors.errorLight,
  },
}))

export const CustomButton3: React.FC = ({ children }) => {
  return <MyThemeButton>{children}</MyThemeButton>
}
