import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react'
import { Paper, Tab, Tabs } from '@mui/material'
import useAllMemory from 'hooks/items/useAllMemoryCached'
import { Pill, Table, Text } from 'components/Shared'
import { getTabData } from './util'
import { colDefs } from 'constants/data/memoryGridColumnDefs'
import { Memory, ItemsTabData } from 'types'

const labelNode = (label: string, value: number) => (
  <>
    {label}
    <Pill label={value} size="small" />
  </>
)

export const MemoryTable = (): JSX.Element => {
  //export default function Items(): ReactElement {
  const [memorys, setMemorys] = useState<Memory[]>([])
  const [filteredMemorys, setFilteredMemorys] = useState<Memory[]>([])
  const [loading, setLoading] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [tabData, setTabData] = useState<ItemsTabData>({
    total: 0,
    new: 0,
    renewed: 0,
    apple: 0,
    windows: 0,
    price: 0,
  })

  const itemsResponse = useAllMemory()

  useEffect(() => {
    const { data, isLoading, isError, isStale } = itemsResponse
    //console.log('MemoryTable - isStale: ', isStale)
    //console.log('MemoryTable - itemResponse hook - result: ', data)
    setMemorys(data || [])
    setFilteredMemorys(data || [])
    setLoading(isLoading)
    setTabData(getTabData(itemsResponse.data || []))
  }, [itemsResponse.data])

  const handleTabChange = (event: SyntheticEvent<Element, Event>, newValue: number) => {
    switch (newValue) {
      case 1:
        setFilteredMemorys(memorys.filter((memory) => memory.condition === 'New'))
        break
      case 2:
        setFilteredMemorys(memorys.filter((memory) => memory.condition === 'Renewed'))
        break
      case 3:
        setFilteredMemorys(memorys.filter((memory) => Number(memory.price) < 100))
        break
      default:
        setFilteredMemorys(memorys)
    }
    setTabValue(newValue)
  }

  return (
    <div>
      <Text variant="body1">
        Current Memory listings from the Amazon Marketplace. Use the table to filter by column or by
        groups using the tabs above the table.
      </Text>
      <Paper
        style={{ width: 'fit-content', overflow: 'hidden', margin: '3.2rem 0 1.6rem' }}
        elevation={0}
      >
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label={labelNode('All', tabData.total)} />
          <Tab label={labelNode('New', tabData.new)} />
          <Tab label={labelNode('Renewed', tabData.renewed)} />
          <Tab label={labelNode('Under $100', tabData.price)} />
        </Tabs>
      </Paper>
      <Table columnDefs={colDefs} rowData={filteredMemorys} paginated={true} />
      <h1 style={{ color: '#F0F2F4' }}>
        amazon laptop prices amazon laptops dell lenovo apple macbook hp asus acer samsung microsoft
        msi intel celeron amd ryzen chromebook
      </h1>
    </div>
  )
}
