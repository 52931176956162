import React, { FC, useEffect, useState } from 'react'
import { debounce, Paper, TablePagination } from '@mui/material'
import { ColumnApi, GridApi } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react/lib/agGridReact'
import { IconCell, LinkCell, PillCell } from './CellRenderers'

import agGridStyleOverrides from './styles'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'

import { Device, DeviceColDefs, Disk, DiskColDefs, Item, ItemColDefs } from 'types'

export interface TableProps {
  rowData: Device[] | Item[] | Disk[] // DeviceData[] | ResidentData[]
  columnDefs: DeviceColDefs[] | ItemColDefs[] | DiskColDefs[] // | ResidentColDefs
  paginated: boolean
}

export const Table: FC<TableProps> = ({ rowData, columnDefs, paginated }: TableProps) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(100)
  const [gridApi, setGridApi] = useState<GridApi>()
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()

  const prevBtn = () => {
    gridApi?.paginationGoToPreviousPage()
    setPage(page - 1)
  }

  const nextBtn = () => {
    gridApi?.paginationGoToNextPage()
    setPage(page + 1)
  }

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newPageSize = parseInt(event.target.value, 10)
    gridApi?.paginationSetPageSize(newPageSize)
    setRowsPerPage(newPageSize)
  }

  const onGridReady = (params: { api: GridApi; columnApi: ColumnApi }) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
    //params.api.sizeColumnsToFit()
    setTimeout(() => {
      params.api.sizeColumnsToFit()
    }, 50)
  }

  useEffect(() => {
    const debouncedResize = debounce(function handleResize() {
      gridApi?.sizeColumnsToFit()
    }, 50)
    window.addEventListener('resize', debouncedResize)
    return () => {
      window.removeEventListener('resize', debouncedResize)
    }
  })

  const defaultColDef = {
    sortable: true,
    filter: true,
    tooltipValueGetter: (params: { value: unknown }) => params.value,
  }
  const frameworkComponents = {
    pillCell: PillCell,
    iconCell: IconCell,
    linkCell: LinkCell,
  }
  const classes = agGridStyleOverrides()

  return (
    <Paper className={`ag-theme-material ${classes['ag-theme-material']}`}>
      <AgGridReact
        pagination={paginated}
        suppressPaginationPanel={true}
        paginationPageSize={rowsPerPage}
        frameworkComponents={frameworkComponents}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        rowData={rowData}
        domLayout="autoHeight"
      />
      {paginated && (
        <TablePagination
          backIconButtonProps={{ onClick: prevBtn }}
          nextIconButtonProps={{ onClick: nextBtn }}
          component="div"
          count={rowData.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  )
}
