import { useEffect, useState } from 'react'
import { specialItemService } from 'services/SpecialItemService'
import { AsyncHookResponse, Item } from 'types'
import { useCancelToken } from 'hooks/util/useCancelToken'
import { CancelToken } from 'axios'

const useAllSpecialItems = (): AsyncHookResponse<Item[]> => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [result, setResult] = useState<Item[]>([])
  const { newCancelToken, isCancel, currentToken } = useCancelToken()

  const fetchItems = (cancelToken: CancelToken) => {
    setLoading(true)
    setError(false)

    specialItemService
      .getAllItems()
      .then((devices) => {
        setResult(devices)
        setLoading(false)
      })
      .catch((e) => {
        console.error('Error:', e)
        setError(true)
        setLoading(false)
      })
  }

  useEffect(() => {
    currentToken?.cancel()
    fetchItems(newCancelToken())
    return () => {
      currentToken?.cancel()
    }
  }, [])

  return { result, loading, error }
}

export default useAllSpecialItems
