import React, { FC, ReactNode } from 'react'

export interface IconCellProps {
  StartIcon?: ReactNode
  EndIcon?: ReactNode
  value: string
}

export const IconCell: FC<IconCellProps> = ({ StartIcon, EndIcon, value }) => {
  return (
    <>
      {StartIcon}
      {value}
      {EndIcon}
    </>
  )
}
