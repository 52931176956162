import React, { FC } from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { Link, LinkProps } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { history } from 'utils/history'

export interface LinkCellProps extends LinkProps, ICellRendererParams {
  label?: string
  href: string
}

export const LinkCell: FC<LinkCellProps> = ({ label, href, value }) => {
  return (
    <Link target="_blank" href={href} rel="noreferrer">
      {label || value}
    </Link>
  )
}
