import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const useAgGridOverrides = makeStyles<Theme>((theme) => ({
  'ag-theme-material': {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    '& .ag-header-row': {
      ...theme.typography.body1,
      fontWeight: 500,
    },
    '& .ag-react-container': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      '& svg': {
        marginRight: '.5rem',
      },
    },
  },
}))

export default useAgGridOverrides
