import React, { ReactElement, useEffect, useState } from 'react'
import { Paper, Box } from '@mui/material'
import { Pill, Text } from 'components/Shared'
import { Todo } from 'types'
import useAllTodos from 'hooks/todos/useAllTodos'

const labelNode = (label: string, value: number) => (
  <>
    {label}
    <Pill label={value} size="small" />
  </>
)

export const Todos = (): ReactElement => {
  const [devices, setDevices] = useState<Todo[]>([])
  const [filteredTodos, setFilteredTodos] = useState<Todo[]>([])
  const [loading, setLoading] = useState(false)
  const [tabValue, setTabValue] = useState(0)

  const todosResponse = useAllTodos()

  useEffect(() => {
    const { result, loading } = todosResponse
    setDevices(result || [])
    setFilteredTodos(result || [])
    setLoading(loading)
  }, [todosResponse.result])

  console.log('todosResponse: ', todosResponse)
  console.log('filteredTodos: ', filteredTodos)
  return (
    <div>
      <Text variant="h1" style={{ padding: '0 0 2rem 0' }}>
        Todos
      </Text>
      <Paper
        style={{ width: 'fit-content', overflow: 'hidden', margin: '3.2rem 0 1.6rem' }}
        elevation={0}
      >
        <Box>
          <Text variant="h1">Todo List</Text>
          {filteredTodos.map((todo, key) => (
            <div key={key}>
              <p>{todo.id}</p>
              <p>{todo.text}</p>
            </div>
          ))}
        </Box>
      </Paper>
    </div>
  )
}
